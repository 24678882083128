import React, { useCallback } from "react";



import ModalCreatePermission from "../components/permissions/ModalCreatePermission";

import { useState, useEffect ,useMemo } from "react";
import { getPermissions } from "../components/permissions/service";

import AlertSuccess from "../common/AlertSuccess";
import AlertError from "../common/AlertError";
import TablePermissionsList from "../components/permissions/TablePermissionsList";




function Permissions() {
  const columns=useMemo(()=>[{
    Header:"N°",
    accessor:"n"

},{
    Header:"ID",
    accessor:"id"

},
{
    Header:"Name",
    accessor:"name"
    
},
{
    Header:"Guard",
    accessor:"guard"
    
},

],[])
  const [data, setData] = React.useState([])
 
  const [pageCount, setPageCount] = React.useState(0)


  const [showAlert, setAlert] = useState(null);
  const fetchIdRef = React.useRef(0)
  const [metaData, setMetaData] = useState({
    permissionsUrl: "/api/permissions",
    prevLink: "",
    nextLink: "",
    count: 0,
    perPage: 10,
    offSet: 0,
  });

//funcion   que se encargara de traer los datos del server
  const fetchData=useCallback(async ({pageIndex, pageSize})=>{

    const fetchId = ++fetchIdRef.current

    if (fetchId === fetchIdRef.current) {
        const response=await getPermissions(`?page=${Number(pageIndex)+1}`)
        let  order 
        const data=response.data.map((item,idx)=>{
           
            if (idx==0) {
                 order= response.meta.from
            } else {
                order= Number(order) + 1
            }
            return {...item,n:order}
        })
        setData(data)
        setPageCount(Math.ceil(response.meta.total / pageSize))
        setMetaData({...metaData,to:response.meta.to,from:response.meta.from})
    }

},[])

  
  return (
    <div>
      <h2 className="text-3xl font-extrabold dark:text-white mb-6">
        <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
          Permisos
        </small>
      </h2>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        

       
                {/**
                 <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Id
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Nombre
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Guard
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions.map((item)=>(<ItemPermission key={item.id} objectItem={item}></ItemPermission>))}
                  </tbody>
                </table>
                 */}

                 {<TablePermissionsList columns={columns}
    data={data}
    fetchData={fetchData}
    pageCount={pageCount}
    metaData={metaData} ></TablePermissionsList>}
                
              
        <div className="w-full flex">{showAlert}</div>

        <div className="flex items-center justify-between pt-3 sm:pt-6">
          <div>
            {/*
              <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(data.count / data.perPage)}
              onPageChange={onPageChange}
            />
            */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Permissions;
