
import { Dropdown } from 'flowbite-react'
import React, { useContext, useState } from 'react'
import ModalDeleteProviderContext from '../../context/Providers/ModalDeleteProvider'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";

function ItemProvider({objectItem}) {
    const [stateProvider,setStateProvider]=useState({...objectItem})
    const {showModalDelete,setShowModalDelete,providerToDelete,setProviderDelete}=useContext(ModalDeleteProviderContext)

function onClickShowModal(params) {

    setShowModalDelete(true)
    setProviderDelete({idProvider:stateProvider.id,nameProvider:stateProvider.name})
    

}

 
    
  return (
    (<tr>
        <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
          {stateProvider.value}
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          {stateProvider.label}
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          {stateProvider.type_doc}
        </td>
        
        <td className="p-4 whitespace-nowrap">
        <Dropdown
              label="Opciones"
              size="xs"
              width="3rem"
              color="dark"
            >
              
              <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickShowModal}>
                Eliminar
              </Dropdown.Item>
              
            </Dropdown>
        </td>
      </tr>)
  )
}

export default ItemProvider