import { type } from "@testing-library/user-event/dist/type/index.js";
import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";


export const getTypeOperations=async(customParams)=>{
    try {
        const response=await client.get("/api/type-operations",{ params:customParams })
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}

export const createTypeOperation=async(type_operation)=>{
    try {
        const response=await client.post("/api/type-operations",type_operation)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}


export const updateTypeOperation=async(type_operation)=>{
    try {
        const response=await client.put(`/api/type-operations/${type_operation.id}`,{name:type_operation.name})
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const deleteTypeOperation=async (type_operation)=>{
    try {
        const response = await client.delete(`/api/type-operations/${type_operation.id}`)
        return response
    } catch (error) {
        throw new Error(error.message)   
    }
}