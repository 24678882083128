
import React, { useEffect } from 'react'
//import Datepicker from "tailwind-datepicker-react"
import { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const options = {
    title: "Fecha",
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("1950-01-01"),
	theme: {
		background: "",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "",
		input: "",
		inputIcon: "",
		selected: "",
	},
    icons: { // () => ReactElement | JSX.Element
        prev: () => <MdKeyboardArrowLeft></MdKeyboardArrowLeft>,
        next: () => <MdKeyboardArrowRight></MdKeyboardArrowRight>,
    },
	datepickerClassNames: "top-12",
	language: "en",
	
}


/**

			<Datepicker selected={optionsDate.defaultDate} options={optionsDate} onChange={handleChange} show={show} setShow={handleClose}  />
		
		*/

const BasicDatePicker = (props) => {
    const [show, setShow] = useState(false)
    const [selected,setSelected]=useState(props.defaultDate)
	console.log(props.defaultDate)
	const handleChange = (selectedDate) => {
        
		setSelected(selectedDate)
		props.onChangeDate(selectedDate)
	}



    const handleClose = (state) => {
        setShow(state)
    }

	useEffect(()=>{
		
		setSelected(props.defaultDate?new Date(props.defaultDate):selected) 
	},[props])

	

	return ( <DatePicker dateFormat={"yyyy-MM-dd"} className='pl-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' selected={selected} onChange={handleChange}  />)
}

export default BasicDatePicker