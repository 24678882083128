

import React, { useState } from 'react'

const initialState = { id:"" , name:""}

function useInitialStateCategoryProduct() {

    const [showModalEdit,setShowModalEdit]=useState(false);
    const [categoryProduct,setCategoryProduct]=useState(initialState)

  return {showModalEdit,setShowModalEdit,categoryProduct,setCategoryProduct}
  
}

export default useInitialStateCategoryProduct