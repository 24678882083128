import React, { useCallback, useEffect } from "react";

import { Avatar , Dropdown , Button } from "flowbite-react";
import * as XLSX from "xlsx";
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import { getPurchase, getPurchases, getTypeDocumentsPayments } from "../components/purchases/service";
import { getTypesDocumentPayment } from "../utils/Sunat/service";
import { useState } from "react";
import ItemPurchaseList from "../components/purchases/ItemPurchaseList";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import ModalViewPurchaseContext from "../context/Purchase/ModalViewPurchaseContext";
import useInitialStateView from "../hooks/purchase/useInitialStateView";
import ModalViewPurchase from "../components/purchases/ModalViewPurchase";
import ModalDeletePurchaseContext from "../context/Purchase/ModalDeletePurchaseContext";
import useInitialStateDelete from "../hooks/purchase/useInitialStateDelete";
import ModalDeletePurchase from "../components/purchases/ModalDeletePurchase";
import TablePurchases from "../components/purchases/TablePurchases";
import { removeEmptyOrNullParams } from "../utils/functions_tools";
import { ToastContainer, toast } from "react-toastify";
import DateRangePicker from "../common/DateRangePicker";
import { getCompanies } from "../components/companies/service";
import { getUsers } from "../components/users/service";
import { getProviders } from "../components/client_providers/service";
import { getUnitMesuarement } from "../components/products/service";
import { getTypeOperations } from "../components/types_operation/service";



function ListPurchase() {

  
 
  const initialStateModalView=useInitialStateView()
  const initialStateodalDelete=useInitialStateDelete()
  
  const [data, setData] = useState([])

  const [pageIndex, setPageIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState({
    
    company: "",
    provider:"",
    responsible:"",
    type_document:"",
    date_buy_init:"",
    date_buy_end:"",
    type_document_payment:""

  });

  const [dataList,setDataList]=useState({
    providers_list:[],

    type_document_list:[],
    companies_list:[],
    units_list:[],
    users_list:[]
  })

  async function onChangeFilter(e) {
    
    setFilters({ ...filters, [e.target.getAttribute("name")]: e.target.value });

  }

  function handleExportButtonExcel(event) {
    const data_file=data.map((item)=>{
      return {n:item.n,tipo_comprobante:item.type_document_payment.description,serie:item.serie,correlativo:item.correlative, fecha_emision_:item.date_buy,ruc_dni:item.supplier_identification_document,sub_total:item.sub_total,igv:item.total_igv, total:item.total ,empresa:item.company.name}
    })
    var wb=XLSX.utils.book_new(),
    ws=XLSX.utils.json_to_sheet(data_file);

    XLSX.utils.book_append_sheet(wb,ws,"Compras")
    XLSX.writeFile(wb,"Compras.xlsx")
  }

  const navigate = useNavigate();

  //funcion   que se encargara de traer los datos del server
  
   const fetchData = async (params) => {
    const paramsReq = {
      page: params.pageIndex,
      company_id:params.company,
      responsible_id:params.responsible,
      supplier_id:params.provider,
      type_document_payment_id:params.type_document_payment,
      date_buy_init:params.date_buy_init,
      date_buy_end:params.date_buy_end,
      page_size:20
    };
    
    
    const spinner = toast.loading(" Cargando... ")
    const updatedParams = removeEmptyOrNullParams(paramsReq);
    const response = await getPurchases(updatedParams);

    let order;

    const list = response.data.map((item, idx) => {

      if (idx === 0) {
        order = response.meta.from;
      } else {
        order = Number(order) + 1;
      }
      return { ...item, n: order };

    });

    toast.dismiss(spinner)
    setData(list);
    setPageCount(Math.ceil(response.meta.total / 20));
    
  };
   



  
    const get_data_filters = async () => {

    const spinner = toast.loading("Cargando...")
    const response_companies = await getCompanies({ q: "all" });
    const response_users = await getUsers({ q: "all" });
    const response_providers = await getProviders({q:"all"})
    const response_units=await getUnitMesuarement({q:"all"})
    const response_type_documents=await getTypeDocumentsPayments({q:"all"})
  
    toast.dismiss(spinner)
    
  
    setDataList({

      providers_list: response_providers.data,
      companies_list: response_companies.data,
      units_list:response_units.data,
      users_list:response_users.data,
      type_document_list:response_type_documents.data

      })
  };
  

  
  useEffect(()=>{
    fetchData({pageIndex:1,...filters})
    setPageIndex(1)
  },[filters])


  useEffect(()=>{
    get_data_filters()
  },[])


  return (

    <ModalDeletePurchaseContext.Provider value={initialStateodalDelete}>
      <ModalViewPurchaseContext.Provider value={initialStateModalView}>
      <div>
      <div className="flex flex-col ">
      <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Listar Compras</h2>

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="grid md:grid-cols-3 grid-cols-2  gap-4 mb-4">
          <div className="flex flex-col items-center justify-center  gap-4 rounded  dark:bg-gray-800">
            <div className="w-full relative">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Rango Fecha de Compra
              </label>
              <div class="absolute bottom-3 z-[1] left-0 flex items-center pl-3.5 pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>

              <DateRangePicker nameStart={"date_buy_init"} nameEnd={"date_buy_end"} state={filters} setState={setFilters}></DateRangePicker>
                  
            </div>

            <div className="w-full">
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Empresa
              </label>
              <select
              onChange={onChangeFilter}
              name="company"
              id="company"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option  value="" selected>Todos</option>
              {dataList.companies_list.map((company)=>(
                <option value={company.id} key={company.id}>{company.name}</option>
              ))}
              
            </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center  gap-4 rounded  dark:bg-gray-800">
            
            <div className="w-full">
              <label
                htmlFor="responsible"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Responsable
              </label>
              <select
              
              onChange={onChangeFilter}
              name="responsible"
                id="responsible"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>Todos</option>
                {dataList.users_list.map((user)=>(
                <option value={user.id} key={user.id}>{user.name}</option>
              ))}
              
              </select>
            </div>
            <div className="w-full">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Proveedor
              </label>
              <select
              onChange={onChangeFilter}
              name="provider"
                id="provider"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>Todos</option>
                {dataList.providers_list.map((provider)=>(
                <option value={provider.id} key={provider.id}>{provider.name}</option>
              ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-start  gap-4 rounded  dark:bg-gray-800">
            

          <div className="w-full">
              <label
                htmlFor="type_document_payment"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Comprobante
              </label>
              <select
              onChange={onChangeFilter}
              name="type_document_payment"
                id="type_document_payment"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>Todos</option>
                {dataList.type_document_list.map((type)=>(
                <option value={type.id} key={type.id}>{type.description}</option>
              ))}
              
              </select>
            </div>
           
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center w-full justify-between">
              <div className="">
                <button
                  onClick={() => {
                    navigate("/register_purchase");
                  }}
                  type="button"
                  className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                >
                  Crear Nuevo
                </button>
              </div>
              <div>
              <button
            onClick={handleExportButtonExcel}
              type="button"
              className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Excel
            </button>
              </div>

                  <ModalViewPurchase></ModalViewPurchase>
                  <ModalDeletePurchase fetchData={fetchData} pageCount setPageIndex={setPageIndex} pageIndex filters={filters} setFilters={setFilters} ></ModalDeletePurchase>
            </div>
      <TablePurchases filters={filters} setFilters={setFilters} data={data} fetchData={fetchData} pageCount={pageCount} pageIndex={pageIndex} setPageIndex={setPageIndex} 
    />
      
    </div>
    </ModalViewPurchaseContext.Provider>
    </ModalDeletePurchaseContext.Provider>

  );
}

export default ListPurchase;
