
import React from 'react'
import {Alert} from  "flowbite-react"
function AlertSuccess({message,setState}) {

  function onCloseAlert() {
    setState(null)
  }

  return (
    <Alert
    
  color="success"
  onDismiss={onCloseAlert}
>
  <span>
    <span className="font-medium">
      {message}
    </span>
    
  </span>
</Alert>
  )
}

export default AlertSuccess