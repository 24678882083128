
import { Sidebar } from 'flowbite-react'
import React from 'react'
import { Link } from 'react-router-dom'
import ListExpenses from '../pages/ListExpenses'
import { HiChartPie,HiShoppingCart,HiUser,HiArchive ,HiSpeakerphone,HiKey,HiShoppingBag,HiTag,HiCreditCard,HiDocumentText,HiFolder} from "react-icons/hi";
import {HiBuildingStorefront} from "react-icons/hi2";
function SidebarComponent() {
  return (
    <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar" >
       <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 ">
          <div className="w-full">
  <Sidebar aria-label="Sidebar with multi-level dropdown example" className='w-full' >
    <Sidebar.Items>
      <Sidebar.ItemGroup>
        <Sidebar.Item
          href="/"
          icon={HiChartPie}
        >
          Dashboard
        </Sidebar.Item>
        
        <Sidebar.Collapse
          icon={HiShoppingCart}
          label="Compras y Gastos"
        >
          <Sidebar.Item href="/register_purchase" icon={HiShoppingBag}>
            Reg. Compra
          </Sidebar.Item>
          <Sidebar.Item href="/register_expenses" icon={HiTag}>
            Reg. Gasto
          </Sidebar.Item>
          <Sidebar.Item href="/list_expenses" icon={HiCreditCard}>
            Listar Gastos
          </Sidebar.Item>
          <Sidebar.Item href="/list_purchases" icon={HiDocumentText}>
            Listar Compras
          </Sidebar.Item>

          <Sidebar.Item href="/types_operation" icon={HiFolder}>
            Categ. Operacion
          </Sidebar.Item>
          
        </Sidebar.Collapse>
        
        <Sidebar.Item
          href="/providers"
          icon={HiSpeakerphone}
        >
          Proveedores
        </Sidebar.Item>

        <Sidebar.Item
          href="/companies"
          icon={HiBuildingStorefront}
        >
          Empresas
        </Sidebar.Item>

        <Sidebar.Collapse icon={HiShoppingCart}
          label="Productos">
        <Sidebar.Item
          href="/products"
          icon={HiArchive}
        >
          Productos
        </Sidebar.Item>
        <Sidebar.Item
          href="/categories_product"
          icon={HiFolder}
        >
          Categ. Productos
        </Sidebar.Item>
        </Sidebar.Collapse>

        <Sidebar.Collapse icon={HiShoppingCart}
          label="Reportes">
        
        <Sidebar.Item
          href="/buys_x_product"
          icon={HiFolder}
        >
          Compras x Prod.
        </Sidebar.Item>
        <Sidebar.Item
          href="/expenses_x_item"
          icon={HiFolder}
        >
          Gastos x Item
        </Sidebar.Item>
        </Sidebar.Collapse>
        
        
        <Sidebar.Collapse
          icon={HiKey}
          label="Configuraciones"
        >
          <Sidebar.Item href="/users" icon={HiUser}>
            Usuarios
          </Sidebar.Item>
          <Sidebar.Item href="/roles" icon={HiTag}>
            Roles
          </Sidebar.Item>
          <Sidebar.Item href="/permissions" icon={HiCreditCard}>
            Permisos
          </Sidebar.Item>
          
        </Sidebar.Collapse>
      </Sidebar.ItemGroup>
    </Sidebar.Items>
  </Sidebar>
</div>
             
          </ul>
       </div>
    </aside>
  )
}

export default SidebarComponent