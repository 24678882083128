import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonDeleteItem from "../../common/ButtonDeleteItem";
import PurchaseContext from "../../context/PurchaseContext";
import SearchSelect from "../../utils/SearchSelect";
import Select from "react-select";
import { getProducts, getUnitMesuarement } from "../products/service";
import ModalCreateProduct from "../products/ModalCreateProduct";
import { Button } from "flowbite-react";



const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#0f172a" : "#0f172a",
  })
};



function PurchaseItem(props) {
  const [stateItemPurchase, setItemPurchase] = useState(props.initialItem);

  const { removeFromList ,state ,setState ,updateItemsDetail} = useContext(PurchaseContext);
  const [categoryList, setCategoryList] = useState(props.categoryList);
  const [unitMeasurementsList, setUnitMeasurement] = useState(props.unitMeasurementsList);
  

  const types_igv = [
    {value:10,name:"IGV 10 %"},
    {value:18,name:"IGV 18 %"},
    {value:0,name:"Exonerado/Inafecto"}
  ]

  function handleChangeSelectProduct(payload) {
    console.log(payload)
    setItemPurchase({ ...stateItemPurchase, product: payload?{id:payload.value,name:payload.label}:null});
  }

  function handleChange(event) {
    event.stopPropagation()
    
    if (
      event.target.name == "typeIgv" ||
      event.target.name == "totalItemPurchase"
    ) {
     
      

      handleCalculateIgvOnChange(event);

    } else {
      setItemPurchase({
        ...stateItemPurchase,
        [event.target.name]: event.target.value,
      });
      
    }
  }

  function onChangeSelectUnitMeasurement(result) {
    setItemPurchase({...stateItemPurchase,unit:result})
  }


  function handleCalculateIgvOnChange(event) {
    if (event.target.name == "typeIgv") {

      let igvAmount;
      switch (event.target.value?Number(event.target.value):"") {
        case 10:
          
          igvAmount = (stateItemPurchase.totalItemPurchase/1.10) * 0.10;
          setItemPurchase({ ...stateItemPurchase, igv: igvAmount ,[event.target.name]:{id:event.target.value,name:event.target.value?event.target.options[event.target.selectedIndex].text:""}});
          break;

        case 18:
          
          igvAmount = (stateItemPurchase.totalItemPurchase/1.18) * 0.18;
          setItemPurchase({ ...stateItemPurchase, igv: igvAmount ,[event.target.name]: {id:event.target.value,name:event.target.value?event.target.options[event.target.selectedIndex].text:""}});
          break;
        case 0:
          setItemPurchase({ ...stateItemPurchase, igv: 0 ,[event.target.name]: {id:event.target.value,name:event.target.value?event.target.options[event.target.selectedIndex].text:""}});
          break;
        
          default:
            setItemPurchase({ ...stateItemPurchase, igv: 0 ,[event.target.name]: {id:event.target.value,name:""}});
            break;
      }
    } else if ((event.target.name == "totalItemPurchase") ) {


      
      let igvAmount;
        
      switch (stateItemPurchase.typeIgv?Number(stateItemPurchase.typeIgv.id):0) {
        case 10:
          
          igvAmount = ((Number(event.target.value)/1.10)*0.10).toFixed(2);
          setItemPurchase({
            ...stateItemPurchase,
            igv: igvAmount,
            [event.target.name]: event.target.value?Number(event.target.value):"",
          });
          break;

        case 18:
          
          igvAmount = ((Number(event.target.value)/1.18)*0.18).toFixed(2);
          console.log(igvAmount)
          setItemPurchase({
            ...stateItemPurchase,
            igv: igvAmount,
            [event.target.name]: event.target.value?Number(event.target.value):"",
          });
          break;
        default:
          setItemPurchase({
            ...stateItemPurchase,
            igv: 0,
            [event.target.name]: event.target.value?Number(event.target.value):"",
          });
          break;
      }
    }
  }


  function removeItem(event) {
    event.stopPropagation();
    removeFromList(stateItemPurchase);
  }

  

  useEffect(() => {

      updateItemsDetail(stateItemPurchase)

  }, [stateItemPurchase]);
  

 

  return (
    <tr
      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
    
    >
      <td
        
        class="px-2 py-4  text-gray-900 whitespace-nowrap dark:text-white flex gap-3 items-center w-72"
      >
        
        <Select

          className="basic-single w-full "
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          name="products"
          styles={customStyles}
          options={props.productList}
          menuPosition="fixed"
          onChange={handleChangeSelectProduct}
          value={stateItemPurchase.product?{value:stateItemPurchase.product.id,label:stateItemPurchase.product.name}:null}

        />
        <ModalCreateProduct getProductsList={props.getProductsList} customParams={{q:"all"}} unitMeasurementsList={unitMeasurementsList} categoryList={categoryList}>
          {" "}
          <Button size="xs">+</Button>{" "}
        </ModalCreateProduct>
      </td>
      <td class="px-6 py-4">
        <div>
          <input
            name="descriptionItem"
            type="text"
            value={stateItemPurchase.descriptionItem}
            id="first_name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="John"
            onChange={handleChange}
            required
            
          />
        </div>
      </td>
      <td class="px-6 py-4">
        <input
          name="quantityProduct"
          type="number"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full max-w-[4rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          required
          onChange={handleChange}
          value={stateItemPurchase.quantityProduct}
        />
      </td>
      <td class="px-2 py-4">


        <Select
          className="basic-single w-36"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          name="unit"
          options={props.unitMeasurementsList}
          menuPosition="fixed"
          onChange={onChangeSelectUnitMeasurement}
          value={stateItemPurchase.unit?stateItemPurchase.unit:""}
          
        />


      </td>

      <td class="px-6 py-4">
        <select
          id="small"
          name="typeIgv"
          class="block w-28 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={handleChange}
        >
          <option value="">
            Seleccionar
          </option>

          {types_igv.map((type)=>(<option selected={type.value===(stateItemPurchase.typeIgv?stateItemPurchase.typeIgv.id:"")?true:false} value={type.value}>{type.name}</option>))}

         
        </select>
      </td>

      <td class="px-2 py-4">
        <input
          name="totalItemPurchase"
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full max-w-[8rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          value={stateItemPurchase.totalItemPurchase}
          onChange={handleChange}
        />
      </td>

      <td class="px-2 py-4">
        <input
          name="igv"
          type="number"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-16 max-w-[12rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          required
          readOnly
          value={stateItemPurchase.igv}
        />
      </td>

      
      <td class="px-6 py-4">
        <ButtonDeleteItem removeItem={removeItem}></ButtonDeleteItem>
      </td>
    </tr>
  );
}

export default PurchaseItem;
