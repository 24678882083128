

import React, { useState } from 'react'

const initialState = { nameRole:"" , idRole:""}

function useInitialDeleteRole() {

    const [showModalDelete,setShowModalDelete]=useState(false);
    const [role,setRole]=useState(initialState)

  return {showModalDelete,setShowModalDelete,role,setRole}
}

export default useInitialDeleteRole