

import  { useState } from 'react'

const initialState = {id:"" ,ruc:"" , name:"", address:""}

function useInitialStateEditCompany() {

    const [showModalEdit,setShowModalEdit]=useState(false);
    const [company,setCompany]=useState(initialState)

  return {showModalEdit,setShowModalEdit,company,setCompany}
}

export default useInitialStateEditCompany