import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";


export const createUser=async (newUser)=>{
    try {
        const response=await client.post("/api/users",newUser)
        
        return response
        
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}


export const getUsers=async (customParams)=>{
    try {
        const response=await client.get("/api/users",{params:customParams})
        
        return response
    } catch (error) {
        
        throw new Error(error.message)
    }
}


export const getUser=async (idUser)=>{

    try {
        const response = await client.get(`/api/users/${idUser}`) 
        console.log(response.data)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}

export const updateUser=async (updatedUser)=>{

    try {
        const response = await client.put(`/api/users/${updatedUser.id}`,updatedUser) 
        console.log(response)
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}


export const deleteUser=async (user)=>{
    try {
        const response =await client.delete(`/api/users/${user.id}`)
        console.log(response)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}