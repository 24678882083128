import { useState } from "react";




const initialState = { id:"" , name:""}


function useInitialStateEditTypeOperation() {

    const [showModalEdit,setShowModalEdit]=useState(false);
    const [type_operation,setTypeOperation]=useState(initialState)
   


  return { showModalEdit,setShowModalEdit,type_operation,setTypeOperation }

}

export default useInitialStateEditTypeOperation