import React, { useEffect, useState } from "react";
import ItemTypeOperation from "../components/types_operation/ItemTypeOperation";
import ModalCreateTypeOperation from "../components/types_operation/ModalCreateTypeOperation";
import ModalDeleteTypeOperation from "../components/types_operation/ModalDeleteTypeOperation";
import ModalEditTypeOperation from "../components/types_operation/ModalEditTypeOperation";
import { getTypeOperations } from "../components/types_operation/service";
import ModalDeleteTypeOperationContext from "../context/TypeOperation/ModalDeleteTypeOperation";

import ModalEditTypeOperationContext from "../context/TypeOperation/ModalEditTypeOperationContext";
import useInitialStateDeleteTypeOperation from "../hooks/TypeOperation/useInitialStateDeleteTypeOperation";
import useInitialStateEditTypeOperation from "../hooks/TypeOperation/useInitialStateEditTypeOperation";



function Types_Operation() {
  const [stateTypeOperationList, setStatTypeOperationList] = useState([]);
  const [showAlert, setAlert] = useState(null);

  const initialStateEditTypeOperation=useInitialStateEditTypeOperation()
  const initialStateDeleteTypeOpertion=useInitialStateDeleteTypeOperation()

async function getTypeOperationList(customParams) {
    try {
        const response=await getTypeOperations(customParams)
        console.log(response)
        setStatTypeOperationList(response.data)
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}


    useEffect(()=>{
        getTypeOperationList({q:"all"})
    },[])


  return (

    <ModalDeleteTypeOperationContext.Provider value={initialStateDeleteTypeOpertion}>

<ModalEditTypeOperationContext.Provider value={initialStateEditTypeOperation}>
        <div>
      <h2 className="text-3xl  font-extrabold dark:text-white mb-6">
        <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
          Categorias de Operacion
        </small>
      </h2>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div className="items-center justify-between lg:flex">
          <div className="mb-4 lg:mb-0">
            <ModalCreateTypeOperation getTypeOperationList={getTypeOperationList}></ModalCreateTypeOperation>
            <ModalEditTypeOperation getTypeOperationList={getTypeOperationList} ></ModalEditTypeOperation>
            <ModalDeleteTypeOperation setAlert={setAlert} getTypeOperationList={getTypeOperationList} ></ModalDeleteTypeOperation>
          </div>
          <div>{showAlert}</div>
        </div>

        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Nombre
                      </th>

                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {stateTypeOperationList.map((item)=>(<ItemTypeOperation objectItem={item}></ItemTypeOperation>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between pt-3 sm:pt-6">
          <div>
           
          
          </div>
          
        </div>
      </div>
    </div>
    </ModalEditTypeOperationContext.Provider>

    </ModalDeleteTypeOperationContext.Provider>

    
    
  );
}

export default Types_Operation;
