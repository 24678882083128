import { Button, Modal, Spinner } from "flowbite-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";
import AlertError from "../../common/AlertError";
import AlertSuccess from "../../common/AlertSuccess";
import ModalEditRoleContext from "../../context/ModalEditRoleContext";
import MultiSelecEditRole from "./MultiSelecEditRole";
import MultiSelect from "../../utils/MultiSelect";
import { getRole, updateRole } from "./service";

const url = "/api/permissions?q=all";

function ModalEditRole(props) {
  const { showModal, setShowModal, setData, data } =
    useContext(ModalEditRoleContext);
  const [showAlert, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const nameRole = useRef(data.name);

  function onCloseModal(params) {
    setShowModal(false);
  }

  async function submitUpdatedRole() {
    setDisabled(true);
    setLoading(true);
    //nueva data del rol que ira al server
    const updatedRole = {
      id: data.idRole,
      name: nameRole.current.value,
      permissions: data.permissions.map((item) => item.value),
    };
    console.log(updatedRole);
    try {
      const updatedRoleResponse = await updateRole(updatedRole);
      if (updatedRoleResponse.status == "success") {
        setLoading(false);
        setAlert(
          <AlertSuccess
            message={updatedRoleResponse.message}
            setState={setAlert}
          ></AlertSuccess>
        );
        setDisabled(false);
        props.gotoPage(0)
        props.fetchData({ pageIndex:0, pageSize:10 })
      }
    } catch (error) {
      setLoading(false);
      setDisabled(false);
      setAlert(
        <AlertError message={error.message} setState={setAlert}></AlertError>
      );
    }
  }

  useEffect(() => {
    if (showModal) {
      nameRole.current.value = data.name;
      setAlert(false);
    }
  }, [showModal]);

  return (
    <React.Fragment>
      <Modal show={showModal} onClose={onCloseModal}>
        <Modal.Header>Editar Rol</Modal.Header>

        <Modal.Body>
          {
            <div className="space-y-6">
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="nameRole"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="nameRole"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Administrador"
                    required
                    name="nameRole"
                    ref={nameRole}
                  />
                </div>
              </div>

              <div class="mb-6">
                <label
                  htmlFor="permissions"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Permisos
                </label>
                <MultiSelecEditRole
                  permissionsLoaded={data.permissions}
                ></MultiSelecEditRole>
              </div>
            </div>
          }
          <div className="mt-6">{showAlert}</div>
          <div className="mt-6">
            {isLoading ? (
              <div className="w-full flex justify-center items-center ">
                <Button color="gray">
                  <Spinner aria-label="Alternate spinner button example" />
                  <span className="pl-3">Cargando...</span>
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            onClick={submitUpdatedRole}
            disabled={isDisabled}
          >
            Actualizar
          </Button>
          <Button color="gray" onClick={onCloseModal} disabled={isDisabled}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ModalEditRole;
