import { getProviders } from "../components/client_providers/service";
import { getCompanies } from "../components/companies/service";
import { getUnitMesuarement } from "../components/products/service";
import { getTypeDocumentsPayments } from "../components/purchases/service";
import { getTypeOperations } from "../components/types_operation/service";
import {  getUsers } from "../components/users/service";




export const onChangeTypeVoucher= function (event ,state,setState ) {

    event.stopPropagation();
    
    setState({...state,voucher:{...state.voucher,type:{id:event.target.value,name:event.target.options[event.target.selectedIndex].text}}})
    
  }



//obtiene la lista de proveedores
export const handleGetProviders= async function (setState ) {

    try {
      const response =await getProviders()
      const providers=response.data.map((provider) => ({
        
        value:provider.identification_document,
        label: provider.name,
        id:provider.id,
        type_doc:provider.type_doc

      }));
      
      setState(providers)
    } catch (error) {
      throw new Error(error.message)
    }
  

  }

  export const onChangeDateExpense= function (value,state,setState) {

    const date=new Date(value)
    setState({...state,date_expense:date.toISOString().split('T')[0]})

  }



  //set status Badge
export const handleBadgeStatePurchase =  function (state) {
    if (state.toUpperCase()==="PENDIENTE") {
        return <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{state}</span>
    } else if (state.toUpperCase()==="REVISADO") {
        return <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500">
        {state}
      </span>
    } else {
      return <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">{state}</span>
    }
}



export const getProvidersList= async function (setState,params) {
  try {
    const response=await getProviders(params)
    setState(response.data)
  } catch (error) {
    throw new Error (error.message)
  }
}



export const companiesList=async (setState)=>{
  try {
    const response = await getCompanies()
    setState(response.data)
  } catch (error) {
    throw new Error (error.message)
  }
}



export const  getTypeDocumentsList = async function (setState) {
  try {
    const response = await getTypeDocumentsPayments()
    setState(response.data)
  } catch (error) {
    throw new Error (error.message)
  }
}  



export async function getUserList(setState ,customParams) {
 
  
    const response = await getUsers(customParams);
    setState(response.data);
  
}



export const getUnitList= async function (setState) {
  try {
    const response = await getUnitMesuarement();
    const unitMeasurements = response.data.map((unit) => ({
      value: unit.id,
      label: unit.name,
      code: unit.code_sunat,
    }));
    setState(unitMeasurements);
  } catch (error) {
    
    throw new Error(error.message);
  }
}


export const getTypeOperationsList=async function (setState,customParams) {
  try {
    const response = await getTypeOperations(customParams);
    const type_operations = response.data.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    
    setState(type_operations);
  } catch (error) {
    
    throw new Error(error.message);
  }
}



