import { useState } from "react";
import { getRole, updateRole as updatedRoleService } from "../components/roles/service";



const initialState = { idRole:null , name:"",permissions:[]}


function useInitialStateEditRoles() {

    const [showModal,setShowModal]=useState(false);
    const [data,setData]=useState(initialState)
   
    
  return {showModal,data,setData,setShowModal}

}

export default useInitialStateEditRoles