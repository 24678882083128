import { Avatar, Dropdown } from "flowbite-react";
import React, { useContext } from "react";
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalDeleteProductContext from "../../context/Products/ModalDeleteProductContext";
import ModalEditProductContext from "../../context/Products/ModalEditProductContext";
import { getCategory } from "./Category/service";
import { getProduct } from "./service";

function ItemProduct({ objectItem }) {
  const {
    showModalEditProduct,
    setShowModalEditProduct,
    productUpdate,
    setProductUpdate,
  } = useContext(ModalEditProductContext);

const {showModalDelete,setModalDelete,productToDelete,setProductToDelete}=useContext(ModalDeleteProductContext)

  async function onClickShowModalEdit() {
    await getProductAndSet(objectItem.id);
    setShowModalEditProduct(true);
  }

  function onClickDeleteProduct() {

    setModalDelete(true)
    setProductToDelete(objectItem)
  }

  async function getProductAndSet(idProduct) {
    try {
      const response = await getProduct(idProduct);
      console.log(response);
      setProductUpdate(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <tr>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        <Avatar img={objectItem.image} />
      </td>
      <td class="px-6 py-4 font-normal text-gray-900 whitespace-nowrap dark:text-white">
        {objectItem.name}
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        {objectItem.description}
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        {objectItem.category_id}
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        {objectItem.unit_measurement}
      </td>

      <td className="p-4 whitespace-nowrap">
        <Dropdown label="Opciones" size="xs" width="3rem" color="dark">
          <Dropdown.Item icon={HiCog} onClick={onClickShowModalEdit}>
            Editar
          </Dropdown.Item>
          <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickDeleteProduct}>Eliminar</Dropdown.Item>
        </Dropdown>
      </td>
    </tr>
  );
}

export default ItemProduct;
