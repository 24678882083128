import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";
import { storage } from "../../utils/storage.js";

const permissionsBaseUrl="/api/permissions"

export const getPermissions = async (params)=>{
    try {
        const response = await client.get(`/api/permissions${params}`);
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
    
}

export const createPermission = async (permission)=>{
    try {
        const response =await client.post(permissionsBaseUrl,permission)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
    

 }