import React, { useContext, useEffect, useState } from "react";
import ButtonDeleteItem from "../../common/ButtonDeleteItem";

import Select from "react-select";

import RegisterExpenseContext from "../../context/RegisterExpenseContext";

function ItemExpenses(props) {
  const [stateItemExpense, setItemExpense] = useState(props.initialItem);
 
  
  const { removeFromList, state, setState, updateItemsDetail } = useContext(
    RegisterExpenseContext
  );


  const types_igv = [
    {id:10,name:"IGV 10 %"},
    {id:18,name:"IGV 18 %"},
    {id:0,name:"Exonerado/Inafecto"}
  ]


  function onChangeSelectTypeOperation(payload) {
    if (payload) {
      setItemExpense({ ...stateItemExpense, category: payload?{id:payload.value,name:payload.label}:null });
    } else {
      setItemExpense({ ...stateItemExpense, category: null });
    }
  }



  function onChangeSelectUnitMeasurement(value) {
    if (value) {
      setItemExpense({ ...stateItemExpense, unit: {code_sunat:value.code , name : value.label} });
    } else {
      setItemExpense({ ...stateItemExpense, unit: value });
    }
    
  }


  function handleChange(event) {
    event.stopPropagation();
    if (
      event.target.name == "typeIgv" ||
      event.target.name == "totalItemExpense"
    ) {
      handleCalculateIgvOnChange(event);
    } else {
      setItemExpense({
        ...stateItemExpense,
        [event.target.name]: event.target.value,
      });
      console.log(event.target.value);
    }
  }

  function handleCalculateIgvOnChange(event) {
    if (event.target.name == "typeIgv") {
      
      let igvAmount;
      switch (event.target.value?Number(event.target.value):null) {
        case 10:
          console.log("entro en el caso  10");
          igvAmount = (stateItemExpense.totalItemExpense / 1.1) * 0.1;
          setItemExpense({
            ...stateItemExpense,
            igv: igvAmount,
            [event.target.name]: {
              id: event.target.value,
              name: event.target.value
                ? event.target.options[event.target.selectedIndex].text
                : "",
            },
          });
          break;

        case 18:
          console.log("entro en el caso  18");
          igvAmount = (stateItemExpense.totalItemExpense / 1.18) * 0.18;
          setItemExpense({
            ...stateItemExpense,
            igv: igvAmount,
            [event.target.name]: {
              id: event.target.value,
              name: event.target.value
                ? event.target.options[event.target.selectedIndex].text
                : "",
            },
          });
          break;
        
        case 0 :
          setItemExpense({
            ...stateItemExpense,
            igv: 0,
            [event.target.name]: {
              id: event.target.value,
              name: event.target.value
                ? event.target.options[event.target.selectedIndex].text
                : "",
            },
          });
        break;
        default:
          setItemExpense({
            ...stateItemExpense,
            igv: 0,
            [event.target.name]:{id:null , name:null}, });
          break;
      }
    } else if (event.target.name == "totalItemExpense") {
      let igvAmount;
          
      switch (
        stateItemExpense.typeIgv ? Number(stateItemExpense.typeIgv.id) : 0
      ) {
        case 10:
          console.log("entro en el caso  10");
          igvAmount = ((Number(event.target.value) / 1.1) * 0.1).toFixed(2);
          setItemExpense({
            ...stateItemExpense,
            igv: igvAmount,
            [event.target.name]: event.target.value
              ? Number(event.target.value)
              : "",
          });
          break;

        case 18:
          console.log("entro en el caso  18");
          igvAmount = ((Number(event.target.value) / 1.18) * 0.18).toFixed(2);
          console.log(igvAmount);
          setItemExpense({
            ...stateItemExpense,
            igv: igvAmount,
            [event.target.name]: event.target.value
              ? Number(event.target.value)
              : "",
          });
          break;
        default:
          setItemExpense({
            ...stateItemExpense,
            igv: 0,
            [event.target.name]: event.target.value
              ? Number(event.target.value)
              : "",
          });
          break;
      }
    }
  }

 


  function removeItem(event) {
    event.stopPropagation();
    removeFromList(stateItemExpense);
  }

  useEffect(() => {
   
    updateItemsDetail(stateItemExpense);

  }, [stateItemExpense]);


  




  return (
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <td class="px-6 py-4">
        <div className="w-44">
          <input
            type="text"
            name="descriptionItem"
            id="descriptionItem"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="John"
            required
            onChange={handleChange}
            value={stateItemExpense.descriptionItem?stateItemExpense.descriptionItem:null}
          />
        </div>
      </td>
      <td class="px-6 py-4">
        <div className="md:w-40 ">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="type_operation"
            options={props.type_operation_list}
            menuPosition="fixed"
            onChange={onChangeSelectTypeOperation}
            value={stateItemExpense.category?props.type_operation_list.find((type)=>(type.value==stateItemExpense.category.id)):null}
          />


          
        </div>
      </td>
      <td class="px-6 py-4">
        <input
          type="number"
          id="quantity"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full max-w-[4rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          required
          name="quantity"
          onChange={handleChange}
          value={stateItemExpense.quantity?stateItemExpense.quantity:null}
        />
      </td>
      <td class="px-6 py-4">
        <div className="md:w-36">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="units"
            options={props.unitMeasurementsList}
            menuPosition="fixed"
            onChange={onChangeSelectUnitMeasurement}
            value={stateItemExpense.unit?props.unitMeasurementsList.find((unit)=>(unit.code==stateItemExpense.unit.code_sunat)):null}

          />
        </div>
      </td>
      <td class="px-6 py-4">
        <select
          id="small"
          name="typeIgv"
          class="block w-28 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={handleChange}
        >
          <option  value={null}>
            Seleccionar
          </option>
          
          {types_igv.map((type)=>(<option value={type.id} selected={(stateItemExpense.typeIgv?stateItemExpense.typeIgv.id:null)==type.id?true:false}>{type.name}</option>))}
         
        </select>
      </td>
      <td class="px-2 py-4">
        <input
          name="totalItemExpense"
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full max-w-[8rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          value={stateItemExpense.totalItemExpense}
          onChange={handleChange}
        />
      </td>

      <td class="px-2 py-4">
        <input
          name="igv"
          type="number"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-16 max-w-[12rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="0"
          required
          readOnly
          value={stateItemExpense.igv}
        />
      </td>
      
      <td class="px-6 py-4">
        <ButtonDeleteItem removeItem={removeItem}></ButtonDeleteItem>
      </td>
    </tr>
  );
}

export default ItemExpenses;
