
import { Dropdown } from 'flowbite-react'
import React, { useContext, useState } from 'react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalDeleteCategoryProductContext from '../../../context/Products/category/ModalDeleteCategoryProductContext';
import ModalEditCategoryProductContext from '../../../context/Products/category/ModalEditCategoryProductContext';


function ItemCategoryProduct({objectItem}) {
  const {showModalEdit,setShowModalEdit,categoryProduct,setCategoryProduct} =useContext(ModalEditCategoryProductContext)
  const {showModalDelete,setShowModalDelete,categoryProductToDelete,setCategoryProductToDelete}=useContext(ModalDeleteCategoryProductContext)

  function onClickEditCategory(params) {

    setShowModalEdit(true)
    setCategoryProduct({id:objectItem.id,name:objectItem.name})

  }

  function onClickDeleteCategoryProduct() {
    console.log("abrir modal")
    setShowModalDelete(true)
    setCategoryProductToDelete({id:objectItem.id,name:objectItem.name})
  }



  return (
    <tr>
        
    <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
     
      <span className="font-semibold">{objectItem.id}</span>
    </td>
    
    <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
      {objectItem.name}
    </td>
    

    <td className="p-4 whitespace-nowrap">
      <Dropdown
        label="Opciones"
        size="xs"
        width="3rem"
        color="dark"
      >
        
        <Dropdown.Item icon={HiCog} onClick={onClickEditCategory}>Editar</Dropdown.Item>
        <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickDeleteCategoryProduct}>
          Eliminar
        </Dropdown.Item>
        
      </Dropdown>
    </td>
  </tr>
  )
}

export default ItemCategoryProduct