import React, { useCallback, useMemo } from "react";
import { Avatar, Dropdown, Button, Pagination } from "flowbite-react";


import { useState, useEffect } from "react";
import { getRoles } from "../components/roles/service";


import AlertError from "../common/AlertError";
import ModalCreateRol from "../components/roles/ModalCreateRol";
import ItemRol from "../components/roles/ItemRol";
import useInitialStateViewRole from "../hooks/useInitialStateViewRole";
import ModalViewRoleContext from "../context/ModalViewRoleContext";
import ModalViewRole from "../components/roles/ModalViewRole";
import useInitialStateEditRoles from "../hooks/useInitialStateEditRoles";
import ModalEditRoleContext from "../context/ModalEditRoleContext";
import ModalEditRole from "../components/roles/ModalEditRole";
import ModalDeleteRole from "../components/roles/ModalDeleteRole";
import ModalDeleteContext from "../context/ModalDeleteRoleContext";
import useInitialDeleteRole from "../hooks/useInitialDeleteRole";
import TableRoles from "../components/roles/TableRoles";



function Roles() {
  
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
 
  const initialStateViewRole = useInitialStateViewRole();
  const initialStateEditRole = useInitialStateEditRoles();
  const initialStateDelete = useInitialDeleteRole();
  const fetchIdRef = React.useRef(0)
  const [metaData, setMetaData] = useState({
    permissionsUrl: "/api/roles",
    prevLink: "",
    nextLink: "",
    count: 0,
    perPage: 10,
    offSet: 0,
  });


  

  //funcion   que se encargara de traer los datos del server
  const fetchData=useCallback(async ({pageIndex, pageSize})=>{

    const fetchId = ++fetchIdRef.current

    if (fetchId === fetchIdRef.current) {
        const response=await getRoles(`?page=${Number(pageIndex)+1}`)
        let  order 
        const data=response.data.map((item,idx)=>{
           
            if (idx==0) {
                 order= response.meta.from
            } else {
                order= Number(order) + 1
            }
            return {...item,n:order}
        })
        setData(data)
        setPageCount(Math.ceil(response.meta.total / pageSize))
        setMetaData({...metaData,to:response.meta.to,from:response.meta.from})
    }

},[])

  

  return (
    <ModalDeleteContext.Provider value={initialStateDelete}>
      <ModalEditRoleContext.Provider value={initialStateEditRole}>
        <ModalViewRoleContext.Provider value={initialStateViewRole}>
          <div>
          <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Roles</h2>
            <TableRoles
    data={data}
    fetchData={fetchData}
    pageCount={pageCount}
    metaData={metaData}></TableRoles>
          </div>
        </ModalViewRoleContext.Provider>
      </ModalEditRoleContext.Provider>
    </ModalDeleteContext.Provider>
  );
}

export default Roles;
