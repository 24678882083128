

import React, { useState } from 'react'

const initiaState={name:"",last_name:"",role:[],email:"",active:null,created_at:""}

function useInitialStateEditUser() {

const [stateUpdateUser,setStateUser]=useState(initiaState)
const [showEditUser,setEditModalUser]=useState(false)


  return {stateUpdateUser,setStateUser,showEditUser,setEditModalUser}
}

export default useInitialStateEditUser