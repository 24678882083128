import React, { useCallback, useEffect, useState } from "react";
import ModalCreateProduct from "../components/products/ModalCreateProduct";
import { Avatar, Dropdown, Button } from "flowbite-react";

import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import {
  getProducts,
  getUnitMesuarement,
} from "../components/products/service";
import AlertError from "../common/AlertError";
import AlertSuccess from "../common/AlertSuccess";
import ItemProduct from "../components/products/ItemProduct";
import ModalEditProductContext from "../context/Products/ModalEditProductContext";
import useInitialStateEditProduct from "../hooks/products/useInitialStateEditProduct";
import ModalEditProduct from "../components/products/ModalEditProduct";
import useInitialDeleteProduct from "../hooks/products/useInitialDeleteProduct";
import ModalDeleteProductContext from "../context/Products/ModalDeleteProductContext";
import ModalDeleteProduct from "../components/products/ModalDeleteProduct";
import { getCategoriesProduct } from "../components/products/Category/service";

import { useReactTable } from "@tanstack/react-table";
import TableProducts from "../components/products/TableProducts";

function Products() {
  const initialStateEditProduct = useInitialStateEditProduct();
  const initialStateDeleteProduct = useInitialDeleteProduct();
  
  const [unitMeasurementsList, setUnitsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [data, setData] = React.useState([]);
  const fetchIdRef = React.useRef(0);
  const [metaData, setMetaData] = useState({
    prevLink: "",
    nextLink: "",
    count: 0,
    perPage: 10,
    offSet: 0,
  });

  async function getUnitList() {
    try {
      const response = await getUnitMesuarement();
      const unitMeasurements = response.data.map((unit) => ({
        value: unit.id,
        label: unit.name,
        code: unit.code_sunat,
      }));

      setUnitsList(unitMeasurements);
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  }

  async function getCategoriesProductList(customParams) {
    try {
      const response = await getCategoriesProduct(customParams);
      const categories = response.data.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryList(categories);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  //funcion   que se encargara de traer los datos del server
  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const response = await getProducts({page:Number(pageIndex)+1});
      let order;
      const data = response.data.map((item, idx) => {
        if (idx == 0) {
          order = response.meta.from;
        } else {
          order = Number(order) + 1;
        }
        return { ...item, n: order };
      });

    
      setData(data);
      setPageCount(Math.ceil(response.meta.total / pageSize));
      setMetaData({
        ...metaData,
        to: response.meta.to,
        from: response.meta.from,
      });
    }
  }, []);

  useEffect(() => {
    getUnitList();
    getCategoriesProductList({q:"all"});
  }, []);

  return (
    <ModalDeleteProductContext.Provider value={initialStateDeleteProduct}>
      <ModalEditProductContext.Provider value={initialStateEditProduct}>
        <div>
          <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">
            Productos
          </h2>
          <TableProducts
            data={data}
            fetchData={fetchData}
            pageCount={pageCount}
            metaData={metaData}
            unitMeasurementsList={unitMeasurementsList}
            categoryList={categoryList}
          ></TableProducts>
        </div>
      </ModalEditProductContext.Provider>
    </ModalDeleteProductContext.Provider>
  );
}

export default Products;
