

import { async } from "q";
import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../../api/client.js";

export const createCategoryProduct=async(category)=>{
    try {
        const response =await client.post("/api/categories",category)
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const getCategoriesProduct=async (customParams)=>{

    try {
        const response= await client.get(`/api/categories`,{params:customParams})
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }

}


export const updateCategoryProduct=async (category)=>{

    try {
        const response=await client.put(`/api/categories/${category.id}`,{name:category.name})
        console.log(response)
        return response
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }


}


export const deleteCategoryProduct=async (category)=>{
    try {
       const response= await client.delete(`/api/categories/${category.id}`)
       return response
    } catch (error) {
        throw new Error(error.message)
    }
}

export const getCategory=async (idCategoryProduct)=>{
    try {
        const response = await client.get(`/api/categories/${idCategoryProduct}`)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}
