import React from 'react'

function GlobalFilter({filter,setFilter}) {

  return (
    <div>
        Search:{""}
        <input value={filter} onChange={(event)=>setFilter(event.target.value)}></input>
    </div>
  )

}

export default GlobalFilter