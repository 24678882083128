
import { Dropdown } from 'flowbite-react'
import React, { useContext } from 'react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { getPurchase } from './service';
import ModalViewPurchaseContext from '../../context/Purchase/ModalViewPurchaseContext';
import ModalDeletePurchaseContext from '../../context/Purchase/ModalDeletePurchaseContext';



function ItemPurchaseList({objectItem}) {

  const {showModalDelete,setModalDelete,purchaseToDelete,setPurchaseToDelete}=useContext(ModalDeletePurchaseContext)
  const {state,addToListDetail,removeFromList,setState,updateItemsDetail,showModalView,setModalView}=useContext(ModalViewPurchaseContext)

    function handleBadgeStatePurchase(state) {
        if (state.toUpperCase()==="PENDIENTE") {
            return <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{state}</span>
        } else if (state.toUpperCase()==="REVISADO") {
            return <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500">
            {state}
          </span>
        } else {
          return <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">{state}</span>
        }
    }


    function onClickShowModalDelete() {

      setPurchaseToDelete(objectItem)
      setModalDelete(true)
  }

    
   


  return (
    <tr>
                      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {objectItem.type_document_payment.description}
                      </td>
                      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {objectItem.serie}
                      </td>
                      <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                        {objectItem.correlative}
                      </td>
                      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {objectItem.date_buy}
                      </td>
                      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {objectItem.supplier_identification_document}
                      </td>
                      <td className="p-4 whitespace-nowrap">
                        {objectItem.sub_total}
                      </td>
                      <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                        {objectItem.total_igv}
                      </td>
                      <td className="p-4 whitespace-nowrap">
                      
                      {handleBadgeStatePurchase(objectItem.status)}
                      </td>

                      <td className="p-4 whitespace-nowrap">
                       { objectItem.total}
                      </td>

                      <td className="p-4 whitespace-nowrap">
                      <Dropdown
                          label="Opciones"
                          size="xs"
                          width="3rem"
                          color="dark"
                        >
                          
                          <Dropdown.Item icon={HiViewGrid}>
                            Ver
                          </Dropdown.Item>
                          <Dropdown.Item icon={HiCog}><Link to={`/edit_purchase/${objectItem.id}`}>Editar</Link></Dropdown.Item>
                          <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickShowModalDelete}>
                            Eliminar
                          </Dropdown.Item>
                          
                        </Dropdown>
                      </td>
                      
                    </tr>
  )
}

export default ItemPurchaseList

