import React, { useContext, useState } from "react";
import { Button, Modal } from "flowbite-react";
import ModalViewRoleContext from "../../context/ModalViewRoleContext";





function ModalViewRole(props) {
    
  const {stateViewRole,setStateViewRole,showViewModal,setShowViewModal}=useContext(ModalViewRoleContext)

  function onClose() {
    setShowViewModal(false)
  }



  return (
    <React.Fragment>
      <Modal show={showViewModal} onClose={onClose}>
        <Modal.Header>Ver Rol</Modal.Header>
        <Modal.Body>
          
            <div class="flex flex-col">
              <div>
                <label
                  htmlFor="name_role"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                 Nombre
                </label>
                <input
                  readOnly
                  type="text"
                  id="name_role"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="John"
                  required
                  value={stateViewRole.name}
                />
              </div>
              <div class="mb-6 mt-4">
              <label
                  
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                 Permisos
                </label>
                <ul class="max-w-md space-y-1 flex flex-wrap gap-x-3 text-gray-500 list-inside dark:text-gray-400">
                  {stateViewRole.permissions.map((permission)=>(<li class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {permission.name}
                  </li>))}
                </ul>
              </div>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ModalViewRole;
