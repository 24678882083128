
import axios from "axios";


const clientDev= axios.create({
    baseURL:process.env.REACT_APP_API_BASE_URL_DEV
})


// me saca la data especifica del response
clientDev.interceptors.response.use(response=>response.data , error=> {
    console.log("algo salio mal desde del client")
    if (!error.response) {
        return Promise.reject({message:error.message})
    } else {
        if (error.response.status === 401) {
            removeAuthorizationHeader()
            window.location = '/login';
          }
        return Promise.reject({
            message:error.response.statusText,
            ...error.response,
            ...error.response.data
        })
    }

}
)

//setea al cliente para que el token viaje en la cabezera en cada llamada a la peticion
export const setAuthorizationHeader=(token)=>clientDev.defaults.headers.common['Authorization']='Bearer '+token

export const removeAuthorizationHeader=()=>{
    delete clientDev.defaults.headers.common['Authorization']
}

export default clientDev