import { useState } from "react";



const useInitialStateDeleteExpense = ()=> {



    const [expenseToDelete,setExpenseToDelete]=useState({});
    const [showModalDelete,setModalDelete]=useState(false)

    

        return { expenseToDelete,setExpenseToDelete,showModalDelete,setModalDelete }

}



export default useInitialStateDeleteExpense