

import React, { useState } from 'react'


const initialState={name:"",last_name:"",roles:[],email:"",active:null,created_at:""}

function useInitialStateViewUser() {

    const [showViewUser,setViewUser]=useState(false)
    const [stateViewUser,setStateViewUser]=useState(initialState)

    

  return {showViewUser,setViewUser,stateViewUser,setStateViewUser}


}

export default useInitialStateViewUser