import { Button, Modal } from "flowbite-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import AlertError from "../../common/AlertError";
import AlertSuccess from "../../common/AlertSuccess";
import ModalEditUserContext from "../../context/Users/ModalEditUserContext";
import { getRoles } from "../roles/service";
import { updateUser } from "./service";

function ModalEditUser(props) {
  const { stateUpdateUser, setStateUser, showEditUser, setEditModalUser } =
    useContext(ModalEditUserContext);
  const nameUser = useRef();
  const lastNameUser = useRef();
  const emailUser = useRef();
  const roleUser = useRef();
  const passwordUser = useRef();
  const passwordConfirmation = useRef();
  const stateUser = useRef();
  const [showAlert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [roles, setRoles] = useState([]);
  

  
  function onClickShowModalEdit() {
    setEditModalUser(true);
  }

  function onCloseModalEdit() {
    setEditModalUser(false);
  }

  async function onSubmitUpdateUser(params) {

    setIsLoading(true)
    setIsDisabled(true)
    setAlert(null)

    //preparo el json de envio
    console.log(stateUpdateUser)
    let updatedUser = {
      id:stateUpdateUser.id,
      name: nameUser.current.value,
      email: emailUser.current.value,
      role: [roleUser.current.value],
      last_name: lastNameUser.current.value,
      active: stateUser.current.value,
    };

    const arrayUpdatedUserValues = Object.values(updatedUser);
    
    //validar si todos los campos obligatorios estan llenos
    const notEmpty = arrayUpdatedUserValues.every((x) => x != null && x != "");
    console.log(notEmpty);
    if (!notEmpty) {
      setAlert(
        <AlertError
          message={"Solo los campos de password son opcionales , el resto deben ser completados"}
          setState={setAlert}
        ></AlertError>
      );
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    //agregar los parametros de password al json de envio , en caso hayan sido llenados
    if (passwordUser.current.value && passwordConfirmation.current.value) {
      if (passwordUser.current.value==passwordConfirmation.current.value) {
        updatedUser={...updatedUser,password:passwordUser.current.value,password_confirmation:passwordConfirmation.current.value}
      } else {
        setAlert(
          <AlertError
            message={"Las contraseñas deben ser iguales"}
            setState={setAlert}
          ></AlertError>
        );
        setIsLoading(false);
      setIsDisabled(false);

        return ;
      }
    } 


     //validar si el formato de email es correcto
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  if (!validateEmail(updatedUser.email)) {
    setAlert(<AlertError message={"Formato de email no valido"} setState={setAlert}
    ></AlertError>)
    setIsLoading(false)
    setIsDisabled(false);
    return;
  } 


  try {
    const response= await updateUser(updatedUser)
    
    setAlert(<AlertSuccess message={response.message} setState={setAlert}></AlertSuccess>)
    setIsLoading(false)
    setIsDisabled(true);
    props.getUserList()
  } catch (error) {
    setAlert(<AlertError message={error.message} setState={setAlert}
    ></AlertError>)
    setIsLoading(false)
    setIsDisabled(false);
  }
    

  }

  async function getRolesList() {
    try {
      const response = await getRoles("?q=all");
      setRoles(response.data);
    } catch (error) {
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }
  }

  useEffect(() => {
    if (showEditUser) {
      
      setIsDisabled(false)
      setAlert(null)
      nameUser.current.value = stateUpdateUser.name;
      lastNameUser.current.value = stateUpdateUser.last_name;
      emailUser.current.value = stateUpdateUser.email;
      if (stateUpdateUser.roles[0]?stateUpdateUser.roles[0].id:null) {
        roleUser.current.value = stateUpdateUser.roles[0].id;
      }
      
      stateUser.current.value = stateUpdateUser.active;
    }
  }, [showEditUser]);


  useEffect (()=>{
    getRolesList();
  },[])

  return (
    <React.Fragment>
      <div>
        <Modal show={showEditUser} onClose={onCloseModalEdit}>
          <Modal.Header>Editar Usuario</Modal.Header>
          <Modal.Body>
            <form>
              <div className="space-y-6">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      for="name"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Nombres
                    </label>
                    <input
                      type="text"
                      id="name"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                      name="name"
                      ref={nameUser}
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="last_name"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Apellidos
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Doe"
                      required
                      name="last_name"
                      ref={lastNameUser}
                    />
                  </div>
                  <div>
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="example@gmail.com"
                      name="email"
                      required
                      ref={emailUser}
                    />
                  </div>
                  <div>
                    <label
                      for="roles"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Rol
                    </label>
                    <select
                      id="roles"
                      name="roles"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      ref={roleUser}
                    >
                      <option selected  value="">
                        Selecciona un Rol
                      </option>

                      {roles.map((role) => (
                        <option value={role.id}>{role.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contraseña
                    </label>
                    <input
                      type="password"
                      id="password"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="********"
                      name="password"
                      required
                      ref={passwordUser}
                    />
                  </div>
                  <div>
                    <label
                      for="password_confirmation"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Confirma contraseña
                    </label>
                    <input
                      type="password"
                      id="password_confirmation"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="********"
                      required
                      name="password_confirmation"
                      ref={passwordConfirmation}
                    />
                  </div>
                  <div>
                    <label
                      for="stateUser"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Estado
                    </label>
                    <select
                      id="stateUser"
                      name="stateUser"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      ref={stateUser}
                    >
                      <option selected value="">
                        Selecciona
                      </option>
                      <option value={1}>ACTIVO</option>
                      <option value={0}>INACTIVO</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div className="w-full flex justify-center mb-5">
              {isLoading ? (
                <button
                  disabled
                  type="button"
                  class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  Cargando
                </button>
              ) : (
                ""
              )}
            </div>
            <div>{showAlert}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onSubmitUpdateUser} disabled={isDisabled}>
              Actualizar
            </Button>
            <Button
              color="gray"
              onClick={onCloseModalEdit}
              disabled={isDisabled}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default ModalEditUser;
