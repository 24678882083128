
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useReactTable,getPaginationRowModel  } from "@tanstack/react-table";
import { getProducts } from '../components/products/service';
import TableExample from '../components/TableExample';
import { getPermissions } from '../components/permissions/service';





function TestPage() {
    const columns=useMemo(()=>[{
        Header:"N°",
        accessor:"n"

    },{
        Header:"ID",
        accessor:"id"

    },
    {
        Header:"Name",
        accessor:"name"
        
    },
    {
        Header:"Guard",
        accessor:"guard"
        
    }
],[])
    const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const fetchIdRef = React.useRef(0)
  const [metaData, setMetaData] = useState({
    permissions: [],
    permissionsUrl: "/api/permissions",
    prevLink: "",
    nextLink: "",
    count: 0,
    perPage: 10,
    offSet: 0,
    from:0,
    to:0
  });

  const fetchData=useCallback(async ({pageIndex, pageSize})=>{

    const fetchId = ++fetchIdRef.current

    if (fetchId === fetchIdRef.current) {
        const response=await getPermissions(`?page=${Number(pageIndex)+1}`)
        let  order 
        const data=response.data.map((item,idx)=>{
           
            if (idx==0) {
                 order= response.meta.from
            } else {
                order= Number(order) + 1
            }
            return {...item,n:order}
        })
        setData(data)
        console.log(response)
        setPageCount(Math.ceil(response.meta.total / pageSize))
        setMetaData({...metaData,to:response.meta.to,from:response.meta.from})
    }

},[])

     
  return (
    <div><TableExample columns={columns}
    data={data}
    fetchData={fetchData}
    pageCount={pageCount}
    metaData={metaData}></TableExample></div>
  )
}

export default TestPage