

import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";

export const createCompany=async (newCompany)=>{
    try {
        const response=await client.post("/api/companies",newCompany)
        return response
    } catch (error) {
        console.log(error)
        if (error.errors && error.errors.name.length > 0) {
            throw new Error(error.errors.name.join("-"))
        } else {
            throw new Error(error.message)
        }
        
    }
}

export const getCompanies=async (params)=>{
    try {
        const response=await client.get("/api/companies")
        return response
    } catch (error) {
        
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }

    }
}

export const getCompany=async (company)=>{
    try {
        const response=await client.get(`/api/companies/${company.id}`)
        return response
    } catch (error) {

        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }

    }
}


export const updateCompany=async (updatedCompany)=>{
    try {
        const response=await client.put(`/api/companies/${updatedCompany.id}`,updatedCompany)
        return response
    } catch (error) {

        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
        
    }

}

export const deleteCompany=async (company)=>{

    try {
        const response = await client.delete(`/api/companies/${company.id}`)
        return response
    } catch (error) {
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }

}

