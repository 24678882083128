

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { handleBadgeStatePurchase } from '../../common/Expense_Purchase_handlers';
import { Dropdown } from 'flowbite-react';
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import { getPurchase } from './service';
import {
  
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ModalDeletePurchaseContext from '../../context/Purchase/ModalDeletePurchaseContext';
import ModalViewPurchaseContext from '../../context/Purchase/ModalViewPurchaseContext';
import ModalViewPurchase from './ModalViewPurchase';
import ModalDeletePurchase from './ModalDeletePurchase';
import { Pagination } from '@mui/material';


const columnHelper = createColumnHelper();



function TablePurchases( { data, fetchData, pageIndex, pageCount,setPageIndex , setFilters , filters} ) {

   
  const {showModalDelete,setModalDelete,purchaseToDelete,setPurchaseToDelete}=useContext(ModalDeletePurchaseContext)

  const {state,addToListDetail,removeFromList,setState,updateItemsDetail,showModalView,setModalView}=useContext(ModalViewPurchaseContext)



function handleView(state) {
    

    setModalView(true);
    setState(state);
  }

  
  const columns = [
    columnHelper.accessor("n", {
      header: () => "Nº",
    }),
    columnHelper.accessor(
      (row) => row.type_document_payment?.description || null,
      {
        id: "voucher",
        header: () => "Comprobante",
      }
    ),
    columnHelper.accessor((row) => (row.serie ? row.serie : null), {
      id: "serie",
      header: "Serie",
    }),

    columnHelper.accessor((row) => row.correlative, {
      id: "voucher_number",
      header: "N° de Comprobante",
    }),

    columnHelper.accessor((row) => row.date_buy, {
      id: "date_buy",
      header: "Fecha Emision",
    }),
    columnHelper.accessor((row) => row.supplier_identification_document, {
      id: "number_provider",
      header: "RUC/DNI",
    }),
    columnHelper.accessor((row) => row.sub_total, {
      id: "sub_total",
      header: "Sub Total",
    }),

    columnHelper.accessor((row) => row.total_igv, {
      id: "igv",
      header: "Igv",
    }),

    columnHelper.accessor((row) => row.total, {
      id: "total",
      header: "Total",
    }),

    columnHelper.accessor((row) => row, {
      id: "status",
      header: "Estado",
      cell: (props) => handleBadgeStatePurchase(props.getValue().status),
    }),

    columnHelper.accessor((row) => row, {
      id: "actions",
      header: "Acciones",
      cell: (props) => (
        <Dropdown label="Opciones" size="xs" width="3rem" color="dark">
          <Dropdown.Item
            icon={HiViewGrid}
            onClick={() => handleView(props.getValue())}
          >
            Ver
          </Dropdown.Item>
          <Dropdown.Item icon={HiCog}>
            <Link to={`/edit_purchase/${props.getValue().id}`}>Editar</Link>
          </Dropdown.Item>
          <Dropdown.Item
            icon={HiCurrencyDollar}
            onClick={() => onClickShowModalDelete(props.getValue())}
          >
            Eliminar
          </Dropdown.Item>
        </Dropdown>
      ),
    }),
  ];
  


  


  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });
      
  const onChangePagination = async (event, page) => {
    
    setPageIndex(page);
    await fetchData({pageIndex:page ,...filters})

  };

      
      

      function onClickShowModalDelete(objectItem) {
        setPurchaseToDelete(objectItem)
        setModalDelete(true)
    }

    

  

  return ( <div>
      <div className="overflow-x-auto rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow sm:rounded-lg">
          
            
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} scope="col" className="px-6 py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-6 py-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center">
            {data.length === 0 && " No hay datos para mostrar"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
        <Pagination
          count={pageCount}
          page={pageIndex}
          color="primary"
          onChange={onChangePagination}
        />
      </div>
    </div>
  )
}

export default TablePurchases