
import { useState } from "react";



const initialState={

    date_buy : "",
    type_payment : "",
    type_document_payment_id: "",
    serie:  "",
    correlative: "",
    supplier_id : "",
    company_id : "",
    details: [],
    total:0,
    total_igv:0,
    sub_total:0,
    status: ""

}

const useInitialStateModalViewExpense=()=>{

    const [state,setState]=useState(initialState);
    const [showModalView,setModalView]=useState(false)

    return {state,setState,showModalView,setModalView}

}

export default useInitialStateModalViewExpense

