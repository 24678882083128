import React, { useEffect, useState } from "react";
import FormEditPurchase from "../components/purchases/FormEditPurchase";
import { useParams } from "react-router-dom";
import useInitialStateEditPurchase from "../hooks/useInitialEditPurchase";
import EditPurchaseContext from "../context/EditPurchaseContext";
import { getPurchase } from "../components/purchases/service";
import { v4 as uuidv4 } from "uuid";
import PurchaseContext from "../context/PurchaseContext";
import useInitialStatePurchase from "../hooks/useInitialStatePurchase";
import { getProducts, getUnitMesuarement } from "../components/products/service";
import AlertError from "../common/AlertError";
import { getUsers } from "../components/users/service";



function PurchaseEdit() {
  const { purchaseId } = useParams();

  const initialState=useInitialStatePurchase()
  const [showAlert,setAlert]=useState(null)

  async function handleGetPurchase() {

    try {
      
      const response= await getPurchase(purchaseId)
      const responseUnits = await getUnitMesuarement();
     
      //llamo las unidades de medida , revisar esto luego para hacerlo de forma mas optimizada
      const unitMeasurements = responseUnits.data.map((unit) => ({
        value: unit.id,
        label: unit.name,
        code: unit.code_sunat,
      }));
      
      const response_users=await getUsers()

      const dateBuy=response.data.date_buy.split("-")
      
      let purchase = {
        id:response.data.id,
        status:response.data.status.toUpperCase(),
        provider:{
            number:response.data.supplier_identification_document,
            name:response.data.supplier_name
        },
        date_purchase: new Date(`${dateBuy[2]}/${dateBuy[1]}/${dateBuy[0]}`),
    
        company:{
            id:response.data.company.id,
            name:response.data.company.name
        },
        voucher:{
            type:{
              id:response.data.type_document_payment.id,
              name:response.data.type_document_payment.description
            } ,
            serie:response.data.serie,
            correlative:response.data.correlative
        },
        type_payment:{id:response.data.type_payment.toUpperCase(),name:response.data.type_payment.toUpperCase()} ,
        detailItems:response.data.details.map((item)=>{
          return {
            uuid: uuidv4(),
      product: {id:item.product.id, name:item.product.name},
      descriptionItem: item.descripcion,
      quantityProduct: item.quantity,
      unit: unitMeasurements.find((unit)=>(unit.code==item.unidad))?unitMeasurements.find((unit)=>(unit.code==item.unidad)):{code:item.unidad},
      typeIgv:{id:Number(item.percentage_igv),name:""},
      igv:item.igv,
      totalItemPurchase: Number(item.item_amount),
          }
        }),

      subTotal:response.data.sub_total,
      total:response.data.total,
      igv:response.data.total_igv,
      serie:response.data.serie,
      correlative:response.data.correlative,
      responsible:response_users.data.find((user)=>(response.data.user_responsible_id===user.id))
    
    }

    
   
    initialState.setState(purchase)

    } catch (error) {
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }

  }


  useEffect(() => {
    
    handleGetPurchase()

  }, []);

  


  return (
    <PurchaseContext.Provider value={initialState}>
      <div>
        <div className="flex md:flex-row flex-col gap-4 md:justify-between">
        <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Editar Compra</h2>
      <div>
        {showAlert}
      </div>
        </div>
      
        <FormEditPurchase setAlert={setAlert}></FormEditPurchase>
      </div>
    </PurchaseContext.Provider>
  );
}

export default PurchaseEdit;
