import { useState } from "react";

const date=new Date()

const initialState={

    provider:{
        number:"",
        name:""
    },
    date_purchase:date.toISOString().split('T')[0],

    company:{
        id:"",
        name:""
    },
    voucher:{
        type:"",
        serie:"",
        correlative:""
    },
    type_payment:"",
    details:[],
    subTotal:0,
    total:0,
    igv:0

}

const useInitialStateView = ()=> {

    const [state,setState]=useState(initialState);
    const [showModalView,setModalView]=useState(false)

    const addToListDetail=(payload)=>{

        setState({
            ...state,
            detailItems:[...state.detailItems,{...payload}]
        })
        
    }

    const removeFromList=(payload)=>{
        setState(
            {
                ...state,
                detailItems:state.detailItems.filter(item=>item.uuid!==payload.uuid)
            }
        )
    }

    const updateItemsDetail=(payload)=>{
        const found=state.detailItems.find((item)=>(item.uuid==payload.uuid))

        if (found) {
            const updatedItems=state.detailItems.map((item)=>(item.uuid==payload.uuid?payload:item))
            setState({...state,detailItems:updatedItems})
        }else {
            setState({...state,detailItems:[...state.detailItems,payload]})
        }
    }

        return {state,addToListDetail,removeFromList,setState,updateItemsDetail,showModalView,setModalView}
}


export default useInitialStateView