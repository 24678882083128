

import React, { useEffect, useState } from 'react'
import FormEditExpense from '../components/expenses/FormEditExpense'
import { getExpense } from '../components/expenses/service';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useInitialStateExpense from '../hooks/useInitialStateExpense';
import RegisterExpenseContext from '../context/RegisterExpenseContext';
import { getCompanies } from '../components/companies/service';
import { getProviders } from '../components/client_providers/service';
import { getTypeDocumentsPayments } from '../components/purchases/service';
import { getTypeOperations } from '../components/types_operation/service';
import { getUnitMesuarement } from '../components/products/service';
import AlertError from '../common/AlertError';
import { getUsers } from '../components/users/service';



function ExpenseEdit() {

  const { expenseId } = useParams();

  const initialState=useInitialStateExpense()
  const [showAlert,setAlert]=useState(null)
  
  async function handleGetExpense() {

    try {
      
      const response= await getExpense(expenseId)
      
      console.log(response.data)
      //const dateBuy=response.date_buy.split("-")

      const companiesList=await getCompanies({q:"all"})
      const providersList=await getProviders({q:"all"})
      const typeDocumentsPaymentList=await getTypeDocumentsPayments({q:"all"})
      const categoryList=await getTypeOperations({q:"all"})
      const unitList=await getUnitMesuarement()
      const response_users=await getUsers({q:"all"})
      const types_igv = [
        {id:10,name:"IGV 10 %"},
        {id:18,name:"IGV 18 %"},
        {id:0,name:"Exonerado/Inafecto"}
      ]
      const dateExpense=response.data.date_expense.split("-")

      console.log(providersList.data)

      let expense = {
        id:response.data.id,
        status:response.data.status.toUpperCase(),
        provider: providersList.data.find((provider)=>(provider.identification_document
          ===response.data.supplier_identification_document)),
        date_expense: `${dateExpense[2]}/${dateExpense[1]}/${dateExpense[0]}`,//usar el formato yyyy/MM/dd para que el datepicker lo puedamostrar correctamente
    
        company: companiesList.data.find((company)=>(company.id==(response.data.company?response.data.company.id:null))),
        voucher:{
            type:typeDocumentsPaymentList.data.find((type)=>(type.id==(response.data.type_document_payment?response.data.type_document_payment.id:null))),
            serie:response.data.serie,
            correlative:response.data.correlative
        },
        type_payment:{id:response.data.type_payment.toUpperCase(),name:response.data.type_payment.toUpperCase()} ,
        detailItems:response.data.details.map((item)=>{
          return {
            uuid: uuidv4(),

      category: categoryList.data.find((category)=>(category.id==(item.type_operation?item.type_operation.id:null))),
      descriptionItem: item.description,
      quantity: Number(item.quantity),
      unit:unitList.data.find((unit)=>(unit.code_sunat==item.unidad)) ,
      typeIgv:types_igv.find((type)=>(type.id==item.percentage_igv)),
      igv:Number(item.igv),
      totalItemExpense: Number(item.item_amount),
          }
        }),
        subTotal:Number(response.data.sub_total),
        total:Number(response.data.tal_igv),
        serie:response.data.serie,
        correlative:response.data.correlative,
        responsible:response_users.data.find((user)=>(response.data.user_responsible_id===user.id))
    }

    console.log(expense.provider)
    initialState.setState(expense)

    } catch (error) {
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }

  }


useEffect(()=>{

  handleGetExpense()

},[])

  return (
    <RegisterExpenseContext.Provider value={initialState}>
      <div>
        <div className="flex md:flex-row flex-col gap-4 md:justify-between">
        <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Editar Gasto</h2>
       
       <div>
        {showAlert}
       </div>
        </div>
      
        <FormEditExpense setAlert={setAlert}></FormEditExpense>
      </div>
    </RegisterExpenseContext.Provider >
    
  )
}

export default ExpenseEdit