import React from "react";
import Layout from "../components/Layout";
import { HiShoppingBag ,HiDatabase ,HiUsers } from "react-icons/hi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const dataPie = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labelsLine = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const dataLine = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
function Dashboard() {
  return (
    <div>
      <h2 className="text-3xl font-extrabold dark:text-white mb-6">
        <small className="ml-2 font-bold  text-gray-900 dark:text-white">
          Dashboard
        </small>
      </h2>

      <div className=" w-full flex justify-center items-center gap-4  flex-col">
        <div className="grid md:grid-cols-4 gap-3 w-full">
          <a
            
            class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <div className="flex gap-4">
                <HiShoppingBag className="w-16 h-16 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></HiShoppingBag>
            <div>
            <h5 class="mb-2 text-2xl font-bold tracking-tight  text-gray-500 dark:text-gray-400">
              Total compras
            </h5>
            <p class="text-lg font-medium text-gray-900 dark:text-white">S/ 0.00</p>
            </div>
            </div>
            
          </a>
          <a
            
            className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <div className="flex gap-4">
                <HiDatabase className="w-16 h-16 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></HiDatabase>
            <div>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-500 dark:text-gray-400">
              Total Gastos
            </h5>
            <p class="text-lg font-medium text-gray-900 dark:text-white">S/ 0.00</p>
            </div>
            </div>
          </a>
          
          <a
            
            class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <div className="flex gap-4">
                <HiUsers className="w-16 h-16 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></HiUsers>
            <div>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-500 dark:text-gray-400">
              Usuarios
            </h5>
            <p class="text-lg font-medium text-gray-900 dark:text-white">10</p>
            </div>
            </div>
          </a>
          
        </div>
        <div className="md:grid-cols-2 grid w-full gap-3 grid-cols-1">
          <div class="w-full p-4  text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <Bar options={options} data={data} />
          </div>
          <div class="w-full p-4  text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <Bar options={options} data={data} />
          </div>
        </div>
        <div className="md:grid-cols-2 grid w-full gap-3 grid-cols-1 ">
          <div class="w-full p-4   text-center max-h-80 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <Doughnut data={dataPie} className="flex flex-row" />;
          </div>
          <div class="w-full p-4   text-center max-h-80 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <Line options={optionsLine} data={dataLine} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
