import client from "../../api/client";
import clientDev from "../../api/clientDev"


const config = {
    headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMsInVzZXJuYW1lIjoiamhleXNvbiIsImlhdCI6MTY4MTQwNTAzNCwiZXhwIjoxNjgxNDkxNDM0fQ.TU_hwqezcZcA4l977rFatyraJJj0blEZWrXqyYa6WSs` }
};


export const createExpense=async (newExpense)=>{
    try {
        //const response = await clientDev.post("/api/expenses",newExpense,config)
        const response = await client.post("/api/expenses",newExpense)
        return response
    } catch (error) {
        console.log(error)
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}



export const getExpenses= async  (paramsReq) =>{

   
    try {
        const response = await client.get(`/api/expenses`,{params:{...paramsReq}})
        console.log(response)
        return response
        
    } catch (error) {
        
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }

}


export const getExpense=async (idExpense) => {

    try {
        const response = await client.get(`/api/expenses/${idExpense}`)
        console.log(response)
        return response
    } catch (error) {

         if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}

export const updateExpense=async (updatedExpense) => {
    try {
        const response =await client.put(`/api/expenses/${updatedExpense.id}`,updatedExpense)
        return response
    } catch (error) {
        
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}


export const deleteExpense = async (expense) => {
    try {
        const response = await client.delete(`/api/expenses/${expense.id}`)
        return response
    } catch (error) {
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}

export const getExpensesDetailService=async (customParams)=>{
    try {
        const response = await client.get(`/api/expenses-details`,{params:customParams})
        return response
    } catch (error) {
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}
