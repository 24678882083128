import { useState } from "react";

const date=new Date()

const initialState={

    provider:{
        id:"",
        number:"",
        name:""
    },
    date_purchase:date,

    company:{
        id:"",
        name:""
    },
    voucher:{
        type:"",
        serie:"",
        correlative:""
    },
    type_payment:{id:"",name:""},
    detailItems:[],
    subTotal:0,
    total:0,
    igv:0,
    status:"",
    responsible:{ id:"",name:""}

}

const useInitialStatePurchase = ()=> {

    const [state,setState]=useState(initialState);

    const addToListDetail=(payload)=>{
        
        setState({
          ...state,
          detailItems: [...state.detailItems, { ...payload }],
        });
        
    }

    const removeFromList=(payload)=>{
        setState(
            {
                ...state,
                detailItems:state.detailItems.filter(item=>item.uuid!==payload.uuid)
            }
        )
    }

    const updateItemsDetail=(payload)=>{
        const found=state.detailItems.find((item)=>(item.uuid==payload.uuid))

        if (found) {
            const updatedItems=state.detailItems.map((item)=>(item.uuid==payload.uuid?payload:item))
            setState({...state,detailItems:updatedItems})
        }else {
            setState({...state,detailItems:[...state.detailItems,payload]})
        }
    }

        return {state,addToListDetail,removeFromList,setState,updateItemsDetail}
}


export default useInitialStatePurchase