

import React, { useContext, useEffect, useState } from "react";
import RegisterExpenseContext from "../../context/RegisterExpenseContext";
import ItemExpenses from "./ItemExpenses";
import { v4 as uuidv4 } from "uuid";

import { getTypeDocumentsList, getTypeOperationsList, getUnitList } from "../../common/Expense_Purchase_handlers";


function ExpensesDetailToUpdate() {
  const { state, addToListDetail, removeFromList } = useContext(
    RegisterExpenseContext
  );

  const [unitMeasurementsList, setUnitMeasurement] = useState([]);  
  const [type_operation_list, setTypeOperationList] = useState([]);
  const { detailItems } = state;

  function addItemToList() {
    const initialValueItem = {
      uuid: uuidv4(),
      descriptionItem: null,
      category: {},
      quantity: 0,
      unit: {},
      typeIgv: null,
      igv: 0,
      totalItemExpense: 0,
      
    };

    addToListDetail(initialValueItem);

  }

  




  useEffect(()=>{

    
      getUnitList(setUnitMeasurement)
      getTypeOperationsList(setTypeOperationList,{q:"all"})

  },[])


  return (
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3">
            Descripcion
          </th>
          <th scope="col" class="px-6 py-3">
            Categ. Operacion
          </th>
          <th scope="col" class="px-6 py-3">
            Cantidad
          </th>
          <th scope="col" class="px-6 py-3">
            Unidad
          </th>
          <th scope="col" class="px-6 py-3">
            Tipo Igv
          </th>
          <th scope="col" class="px-6 py-3">
            Total
          </th>
          <th scope="col" class="px-6 py-3">
            IGV
          </th>
          
          <th scope="col" class="px-6 py-3">
            Acciones
          </th>
        </tr>
      </thead>
      <tbody>
        {detailItems.map((item) => ( <ItemExpenses initialItem={item} key={item.uuid} unitMeasurementsList={unitMeasurementsList} type_operation_list={type_operation_list}  ></ItemExpenses> ))}
      </tbody>
      <tfoot>
        <tr class="font-semibold text-gray-900 dark:text-white">
          <th scope="row" class="px-6 py-3 text-base">
            <button
              type="button"
              class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 px-3 py-2 text-xs font-medium text-center inline-flex  rounded-lg items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
              onClick={addItemToList}
            >
              Agregar Item
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  );
}

export default ExpensesDetailToUpdate;