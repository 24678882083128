import { Dropdown } from 'flowbite-react'
import React, { useContext, useState } from 'react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalDeleteTypeOperationContext from '../../context/TypeOperation/ModalDeleteTypeOperation';
import ModalEditTypeOperationContext from '../../context/TypeOperation/ModalEditTypeOperationContext';
import ModalEditTypeOperation from '../../context/TypeOperation/ModalEditTypeOperationContext';


function ItemTypeOperation({objectItem}) {
    const { showModalEdit,setShowModalEdit,type_operation,setTypeOperation } =useContext(ModalEditTypeOperationContext)
    const { showModalDelete,setShowModalDelete,type_operation_to_delete,setTypeOperationToDelete }=useContext(ModalDeleteTypeOperationContext)
    
    function onClickEdiTypeOperation(params) {
        
        setTypeOperation(objectItem)
        setShowModalEdit(true)
    }


    function onClickDeleteTyOperation(params) {
        setShowModalDelete(true)
        setTypeOperationToDelete(objectItem)
    }
    

  return <tr>
        
  <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
   
    <span className="font-semibold">{objectItem.id}</span>
  </td>
  
  <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
    {objectItem.name}
  </td>
  

  <td className="p-4 whitespace-nowrap">
    <Dropdown
      label="Opciones"
      size="xs"
      width="3rem"
      color="dark"
    >
      
      <Dropdown.Item icon={HiCog} onClick={onClickEdiTypeOperation}>Editar</Dropdown.Item>
      <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickDeleteTyOperation}>
        Eliminar
      </Dropdown.Item>
      
    </Dropdown>
  </td>
</tr>
}

export default ItemTypeOperation