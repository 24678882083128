import React, { useEffect, useMemo, useReducer, useState } from "react";
import DateRangePicker from "../common/DateRangePicker";
import Pagination from "@mui/material/Pagination";
import * as XLSX from "xlsx";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import client from "../api/client";
import { getBuysDetail } from "../components/purchases/service";
import { getExpensesDetailService } from "../components/expenses/service";
import { getUsers } from "../components/users/service";
import { getProviders } from "../components/client_providers/service";
import { ToastContainer, toast } from "react-toastify";
import { getTypeOperations } from "../components/types_operation/service";
import { getDateFromValue, removeEmptyOrNullParams } from "../utils/functions_tools";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: () => "Nº",
  }),
  columnHelper.accessor((row)=>getDateFromValue(row.date_expense), {
    header: () => "Fecha Gasto",
    id:"date_expense"
  }),
  columnHelper.accessor("descripcion", {
    header: () => "Descripcion",
  }),
  columnHelper.accessor("typeOperation", {
    header: () => "Categoria",
  }),

  columnHelper.accessor((row)=>`${row.quantity} ${row.unidad}`, {
    header: () => "Cantidad",
    id:"quantity"
  }),
  columnHelper.accessor("igv", {
    header: () => "Igv",
  }),
  columnHelper.accessor("item_amount", {
    header: () => "Total",
  }),
];

function ExpensesxItem() {
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState({
    user_register: "",
    responsible: "",
    provider: "",
    start_register: "",
    end_register: "",
    type_operation: "",
  });

  const [filters_data, setFiltersData] = useState({
    users_data: [],
    responsibly_data: [],
    providers_data: [],
    type_operations: [],
  });

  const get_data_filters = async () => {
    const spinner = toast.loading("Cargando...");
    const response_users = await getUsers({ q: "all" });
    const response_providers = await getProviders({ q: "all" });
    const response_type_operations = await getTypeOperations({ q: "all" });
    
    toast.dismiss(spinner);
    setFiltersData({
      start: "",
      end: "",
      users_data: response_users.data,
      responsible_data: response_users.data,
      providers_data: response_providers.data,
      type_operations: response_type_operations.data,
    });
  };

  function onChangeFilter(e) {
   
    setFilters({ ...filters, [e.target.getAttribute("name")]: e.target.value });
    
  }

  const loadData = async (params) => {
    const customParams = {
      date_expense_init: params.start_register,
      date_expense_end: params.end_register,
      page: params.pageIndex,
      user_id: params.user_register,
      responsible_id: params.responsible,
      supplier_id: params.provider,
      type_operation_id: params.type_operation,
      page_size:20
    };
    const updatedParams = removeEmptyOrNullParams(customParams);
    const spinner = toast.loading("Cargando...");
    const response = await getExpensesDetailService(updatedParams);
    const { data } = response;
    
    setData(data.map((item)=>{
      return {...item,date_register:getDateFromValue(item.date_register)}
  }) );                                     
    setPageCount(Math.ceil(response.meta.total / 20));
    toast.dismiss(spinner);
  };

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const onChangePagination = async (event, page) => {
    
    await loadData({pageIndex:page ,...filters})
    setPageIndex(page);
    
  };

  function handleExportButtonExcel(event) {
    var wb=XLSX.utils.book_new(),
    ws=XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb,ws,"ComprasxProducto")
    XLSX.writeFile(wb,"ComprasxProducto.xlsx")
  }

  useEffect(() => {
    get_data_filters();
  }, []);

  useEffect(() => { 
    loadData({pageIndex:1,...filters})
      setPageIndex(1)
    }, [filters]);

  return (
    <div className="">
      <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">
        Gastos x Item
      </h2>
      <div className="">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="grid md:grid-cols-3 grid-cols-2  gap-4 mb-4">
          <div className="flex flex-col items-center justify-center  gap-4 rounded  dark:bg-gray-800">
            <div className="w-full relative">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Rango Fecha de Gasto
              </label>
              <div class="absolute bottom-3 z-[1] left-0 flex items-center pl-3.5 pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <DateRangePicker
                nameStart={"start_register"} nameEnd={"end_register"}
                state={filters}
                setState={setFilters}
              ></DateRangePicker>
            </div>

            <div className="w-full">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Usuario Registro
              </label>
              <select
                onChange={onChangeFilter}
                name="user_register"
                id="user_register"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>
                  Todos
                </option>
                {filters_data.users_data.map((user) => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center  gap-4 rounded  dark:bg-gray-800">
            <div className="w-full">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Tipo Operacion
                
              </label>
              <select
                onChange={onChangeFilter}
                name="type_operation"
                id="type_operation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>
                  Todos
                </option>
                {filters_data.type_operations.map((type) => (
                  <option value={type.id}>{type.name}</option>
                ))}
              </select>
            </div>

            <div className="w-full">
              <label
                htmlFor="user_responsible"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Responsable
              </label>
              <select
                onChange={onChangeFilter}
                name="responsible"
                id="user_responsible"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>
                  Todos
                </option>

                {filters_data.users_data.map((user) => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-start  gap-4 rounded  dark:bg-gray-800">
            <div className="w-full">
              <label
                htmlFor="provider"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Seleccione Proveedor
              </label>
              <select
                onChange={onChangeFilter}
                name="provider"
                id="provider"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected>
                  Todos
                </option>
                {filters_data.providers_data.map((provider) => (
                  <option value={provider.id}>{provider.name}</option>
                ))}
              </select>
            </div>

            
          </div>
        </div>
        <div className="grid md:grid-cols-5 grid-cols-3  gap-4 mb-4">
          <div className="flex flex-col items-center justify-center h-24 gap-2 rounded  dark:bg-gray-800">
            <button
            onClick={handleExportButtonExcel}
              type="button"
              className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Exportar
            </button>
          </div>

          <div className="flex flex-col items-center justify-center h-24 gap-2 rounded "></div>

          <div className="flex flex-col items-center justify-center h-24 gap-2 rounded "></div>
        </div>
        <div className="flex items-center justify-center  mb-4 rounded ">
          <div className="relative overflow-x-auto w-full">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} scope="col" className="px-6 py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-6 py-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center">
            {data.length === 0 && " No hay datos para mostrar"}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
          <Pagination
            page={pageIndex}
            count={pageCount}
            color="primary"
            onChange={onChangePagination}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpensesxItem;
