import ButtonLogout from "../../common/ButtonLogout"
import { logout } from './service.js';
import { useContext, useEffect, useRef } from "react";
import AuthContext from "./context";
import { Link, useNavigate } from "react-router-dom";
function AuthButton(params) {

    const refButton=useRef(null)
    const {isLogged,handleLogout:onLogout}=useContext(AuthContext)
    const navigate = useNavigate();
  async function handleLogoutClick() {

    await logout()
    onLogout()
    navigate("/login",{replace:true})
    
  }
    
  useEffect(()=>{
    
    console.log(refButton.current)
  },[])


    return <ButtonLogout ref={refButton} className="header-button" onClick={handleLogoutClick}>
    Salir
  </ButtonLogout>
}

export default AuthButton