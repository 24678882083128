import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import "flowbite";
import LoginPage from './pages/LoginPage';
import Layout from './components/Layout';
import RegisterPurchase from './pages/RegisterPurchase';
import RegisterExpenses from './pages/RegisterExpenses';
import ListExpenses from './pages/ListExpenses';
import ListPurchase from './pages/ListPurchase';
import Users from './pages/Users';
import Providers from './pages/Providers';
import Products from './pages/Products';
import AuthContext from './components/auth/context';
import { useState } from "react";
import RequireAuth from "./components/auth/RequireAuth.js";
import Permissions from './pages/Permissions';
import Roles from './pages/Roles';
import CategoriesProduct from './pages/CategoriesProduct';
import Types_Operation from './pages/Types_Operation';
import Companies from './pages/Companies';
import PurchaseEdit from './pages/PurchaseEdit';
import ExpenseEdit from './pages/ExpenseEdit';
import TableExample from './components/TableExample';
import TestPage from './pages/TestPage';
import UserDataLoged from './context/UserDataLoged';

import useInitialStateUserLoged from './hooks/useInitialStateUserLoged';
import BuysxProduct from './pages/BuysxProduct';
import ExpensesxItem from './pages/ExpensesxItem';


function App({isInitializeLogged}) {

  const [isLogged,setIsLogged]=useState(isInitializeLogged)
  const [isLoading,setIsLoading]=useState(false)
   
  const initialStateUserLogged=useInitialStateUserLoged()

  function handleLogin() {
    setIsLogged(true)
  }

  function handleLogout() {
    setIsLogged(false)
  }

//<RequireAuth>

  return (
    <UserDataLoged.Provider value={initialStateUserLogged}>
    <BrowserRouter>
    
    <AuthContext.Provider value={{isLogged,handleLogin,handleLogout}}>
   <Routes>
   <Route path="/login" element={<LoginPage onLogin={handleLogin}></LoginPage>} />
      
      
      <Route path='/' element={<RequireAuth><Layout></Layout></RequireAuth>}>
        
        <Route index element={<Dashboard />} />
        <Route path='register_purchase' element={<RegisterPurchase></RegisterPurchase>}></Route>
        <Route path='register_expenses' element={<RegisterExpenses></RegisterExpenses>}></Route>
        <Route path='list_expenses' element={<ListExpenses></ListExpenses>}></Route>
        <Route path='list_purchases' element={<ListPurchase></ListPurchase>}></Route>
        <Route path='users' element={<Users></Users>}></Route>
        <Route path='providers' element={<Providers></Providers>}></Route>
        <Route path='products' element={<Products></Products>}></Route>
        <Route path='permissions' element={<Permissions></Permissions>}></Route>
        <Route path='roles' element={<Roles></Roles>}></Route>
        <Route path='categories_product' element={<CategoriesProduct></CategoriesProduct>}></Route>
        <Route path='types_operation' element={<Types_Operation></Types_Operation>}></Route>
        <Route path='companies' element={<Companies></Companies>}></Route>
        <Route path='buys_x_product' element={<BuysxProduct></BuysxProduct>}></Route>
        <Route path='expenses_x_item' element={<ExpensesxItem></ExpensesxItem>}></Route>
        <Route path='edit_purchase/:purchaseId' element={<PurchaseEdit></PurchaseEdit>}></Route>
        <Route path='edit_expense/:expenseId' element={<ExpenseEdit></ExpenseEdit>} ></Route>
        <Route path='test' element={<TestPage></TestPage>}></Route>
      </Route>
      
      
    
      
    </Routes>
   </AuthContext.Provider>
    
   
    
      
    </BrowserRouter>
    </UserDataLoged.Provider>
  );
}

export default App;
