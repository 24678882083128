
import { Button, Modal } from 'flowbite-react'
import React, { useContext, useEffect, useState } from 'react'
import ModalViewUserContext from '../../context/Users/ModalViewUserContext'





function ModalViewUser() {

  const [state , setState]=useState({})
   const {showViewUser,setViewUser,stateViewUser,setStateViewUser} =useContext(ModalViewUserContext)
  const [created_at,setCreated_at]=useState()

  function onCloseModalViewUser(event) {
    setViewUser(false)
  }

  
  

  useEffect(()=>{

    if (showViewUser) {
    
      setState(stateViewUser)
      const date_format=new Date(stateViewUser.created_at)
      
      setCreated_at(date_format.toISOString().split('T')[0])
    }

  },[showViewUser])


  return (
    <React.Fragment>
  <Modal
    show={showViewUser}
    onClose={onCloseModalViewUser}
    size="md"
  >
    <Modal.Header>
      Ver Usuario
    </Modal.Header>
    <Modal.Body>
    <div class="w-full max-w-sm bg-white  rounded-lg dark:bg-gray-800 ">
    <div class="flex justify-end px-4 pt-4">
        
        
        
    </div>
    <div class="flex flex-col items-center pb-10">
        <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="https://images.pexels.com/photos/1438081/pexels-photo-1438081.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Bonnie image"/>
        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{`${state.name} ${state.last_name}`}</h5>
        <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 mb-3">{state.roles?state.roles.map((role)=>(role?role.name:"")):""}</span>
        <span class="text-sm  text-gray-500 dark:text-gray-400">{state.email}</span>
        {state.active==1?<span class="bg-green-100 mt-3 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ACTIVO</span>:<span class="bg-pink-100 mt-3 text-pink-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">INACTIVO</span>}
        <div class="flex gap-2 pt-3">
                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                            Creado : 
                        </p>
                        <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                            {created_at}
                        </p>
                    </div>
    </div>
</div>
    </Modal.Body>
    <Modal.Footer>
      
      <Button
        color="gray"
        onClick={onCloseModalViewUser}
      >
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
</React.Fragment>
  )
}

export default ModalViewUser