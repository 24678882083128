import React from 'react'
import {Alert} from  "flowbite-react"



function AlertError({message,setState}) {
    function onCloseAlert() {
      setState(null)
      }
  return (
    <Alert
  color="failure"
  onDismiss={onCloseAlert}
>
  <span>
    <span className="font-medium">
      {message}
    </span>
    
  </span>
</Alert>
  )
}

export default AlertError