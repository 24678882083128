import React, { useContext, useEffect, useState } from "react";

import BasicDatePicker from "./BasicDatePicker";
import { BiTrash } from "react-icons/bi";
import ModalCreateProvider from "./client_providers/ModalCreateProvider";
import PurchasesListItems from "./purchases/PurchasesListItems";
import PurchaseContext from "../context/PurchaseContext";

import Select from "react-select";
import { getProviders } from "./client_providers/service";
import { getCompanies } from "./companies/service";
import { getTypeDocumentsPayments } from "./purchases/service";
import ModalPreviewRegisterPurchase from "./purchases/ModalPreviewRegisterPurchase";
import { useRef } from "react";
import AlertError from "../common/AlertError";
import {
  getProvidersList,
  getUserList,
} from "../common/Expense_Purchase_handlers";

function FormRegisterPurchase(props) {
  
  const { state, setState } = useContext(PurchaseContext);

  const { detailItems } = state;
  const [providersList, setProvidersList] = useState([]);

  const [companiesList, setCompaniesList] = useState([]);

  const [typeDocumentsPaymentList, setTypeDocumentsList] = useState([]);

  const [usersList, setUserList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const formPurchase = useRef();

  function onChangeTypeVoucher(event) {
    event.stopPropagation();

    setState({
      ...state,
      voucher: {
        ...state.voucher,
        type: {
          id: event.target.value,
          name: event.target.options[event.target.selectedIndex].text,
        },
      },
    });
  }

  function onChangeDatePurchase(value) {
    const date = new Date(value);
    setState({ ...state, date_purchase: date.toISOString().split("T")[0] });
  }

  function onChangeSelectProvider(provider) {
    if (provider) {
      setState({
        ...state,
        provider: {
          number: provider.value,
          name: provider.label,
          id: provider.id,
        },
      });
    } else {
      setState({ ...state, provider: provider });
    }
  }

  function onChangeSelectTypePayment(event) {
    event.stopPropagation();
    setState({
      ...state,
      type_payment: {
        id: event.target.value,
        name: event.target.options[event.target.selectedIndex].text,
      },
    });
  }

  function handleSumTotals() {
    const reducer_igv = (accumulator, currentValue) =>
      Number(accumulator) + Number(currentValue.igv);
    const sum_igv = state.detailItems.reduce(reducer_igv, 0);

    const reducer_total = (accumulator, currentValue) =>
      Number(accumulator) + Number(currentValue.totalItemPurchase);
    const sum_total = state.detailItems.reduce(reducer_total, 0);

    setState({
      ...state,
      igv: Number(sum_igv).toFixed(2),
      total: Number(sum_total).toFixed(2),
      subTotal: Number(sum_total).toFixed(2) - Number(sum_igv).toFixed(2),
    });
  }

  function onChangeSelectCompany(event) {
    setState({
      ...state,
      company: {
        id: event.target.value,
        name: event.target.options[event.target.selectedIndex].text,
      },
    });
  }

  async function getCompaniesList() {
    try {
      const response = await getCompanies();
      setCompaniesList(response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async function getTypeDocumentPaymentList() {
    try {
      const response = await getTypeDocumentsPayments();

      setTypeDocumentsList(response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  function handleCleanFields() {
    formPurchase.current.reset();
    setState({ ...state, detailItems: [] });
  }

  function handleChangeVoucherInputs(event) {
    event.stopPropagation();
    setState({
      ...state,
      voucher: {
        ...state.voucher,
        [event.target.name]: event.target.value.toUpperCase(),
      },
    });
  }

  function onChangeSelectResponsible(payload) {
    if (payload) {
      setState({
        ...state,
        responsible: { id: payload.value, name: payload.label },
      });
    } else {
      setState({ ...state, responsible: { id: "", name: "" } });
    }
  }

  useEffect(() => {
    handleSumTotals();
  }, [detailItems]);

  useEffect(() => {
    try {
      getTypeDocumentPaymentList();
      getCompaniesList();
      getProvidersList(setProvidersList, { q: "all" });

      getUserList(setUserList,{q:"all"});
    } catch (error) {
      props.setAlert(
        <AlertError
          message={error.message}
          setState={props.setAlert}
        ></AlertError>
      );
    }
  }, []);

  return (
    <div>
      <form ref={formPurchase} onSubmit={() => {}}>
        <div class="p-2  mb-4  bg-white gap-4 border grid border-gray-200 rounded-lg shadow-sm md:grid-cols-2 dark:border-gray-700 sm:p-2 dark:bg-gray-800">
          <div class="p-3 space-y-6  bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-3 sm:pt-4 dark:bg-gray-800">
            <div class="mb-4 flex items-end gap-5">
              <div className="md:w-56">
                <label
                  htmlFor="provider_purchase"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Proveedor :
                </label>
                <Select
                  className="basic-single z-10"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="providers"
                  options={providersList.map((provider) => ({
                    value: provider.identification_document,
                    label: provider.name,
                    id: provider.id,
                  }))}
                  menuPosition="fixed"
                  onChange={onChangeSelectProvider}
                />
              </div>
              <ModalCreateProvider
                setProvidersList={setProvidersList}
              ></ModalCreateProvider>
            </div>
            <div class="grid gap-4 mb-6 md:grid-cols-2">
              <div>
                <label
                  htmlFor="first_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Fecha de compra
                </label>

                <div class="relative max-w-sm">
                  <div class="absolute z-[1] inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <BasicDatePicker
                    onChangeDate={onChangeDatePurchase}
                  ></BasicDatePicker>
                </div>
              </div>

              <div>
                <label
                  htmlFor="company"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Empresa :
                </label>
                <select
                  onChange={onChangeSelectCompany}
                  id="company"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="company"
                >
                  <option selected value="">
                    Selecciona
                  </option>

                  {companiesList.map((company) => (
                    <option value={company.id}>{company.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="type_payment"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tipo de pago
                </label>
                <select
                  onChange={onChangeSelectTypePayment}
                  name="type_payment"
                  id="type_payment"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="">
                    Selecciona
                  </option>
                  <option value="PLIN">Plin</option>
                  <option value="YAPE">Yape</option>
                  <option value="EFECTIVO">Efectivo</option>
                  <option value="DEPOSITO">Deposito</option>
                  <option value="TARJETA">Tarjeta</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="responsible"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Responsable
                </label>
                <Select
                  className="basic-single z-10"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="responsible"
                  options={usersList.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  menuPosition="fixed"
                  onChange={onChangeSelectResponsible}
                />
              </div>
            </div>
          </div>

          <div class="p-4  bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div class="mb-6 flex flex-col gap-4">
              <div>
                <label
                  htmlFor="type_voucher"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tipo de Comprobante
                </label>
                <select
                  onChange={onChangeTypeVoucher}
                  id="type_voucher"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="type_voucher"
                >
                  <option selected value="">
                    Selecciona
                  </option>
                  {typeDocumentsPaymentList.map((item) => (
                    <option value={item.id}>{item.description}</option>
                  ))}
                </select>
              </div>
              <div
                class="grid gap-6 mb-6 md:grid-cols-2"
                onChange={handleChangeVoucherInputs}
              >
                <div>
                  <label
                    htmlFor="serie"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Serie
                  </label>
                  <input
                    type="text"
                    id="serie"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="BB01"
                    required
                    maxLength="4"
                    name="serie"
                  />
                </div>
                <div>
                  <label
                    htmlFor="correlative"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Correlativo
                  </label>
                  <input
                    type="text"
                    id="correlative"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="00000003452"
                    required
                    maxLength="8"
                    name="correlative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="p-4 mb-4  bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div class="relative overflow-x-auto ">
          <PurchasesListItems></PurchasesListItems>
        </div>
        <div className="grid w-full ">
          <div class="mb-4">
            <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      Subtotal :
                    </p>
                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.subTotal}
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      IGV :
                    </p>
                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.igv}
                  </div>
                </div>
              </li>

              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      Total :
                    </p>
                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.total}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ModalPreviewRegisterPurchase
            showModal={showModal}
            setShowModal={setShowModal}
            handleCleanFields={handleCleanFields}
          ></ModalPreviewRegisterPurchase>
        </div>
      </div>
    </div>
  );
}

export default FormRegisterPurchase;
