import React, { useContext, useState } from "react";
import { Dropdown } from "flowbite-react";
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalViewUserContext from "../../context/Users/ModalViewUserContext";
import ModalEditUserContext from "../../context/Users/ModalEditUserContext";
import { getUser } from "./service";
import ModalDeleteUserContext from "../../context/Users/ModalDeleteUserContext";

function ItemUser({ objectItem }) {
  const [state, setState] = useState({ ...objectItem });
  const { showViewUser, setViewUser, stateViewUser, setStateViewUser } =
    useContext(ModalViewUserContext);
  const { stateUpdateUser, setStateUser, showEditUser, setEditModalUser } =
    useContext(ModalEditUserContext);
  const {
    showModalDeleteUser,
    setModalDeleteUser,
    userToDelete,
    setUserDelete,
  } = useContext(ModalDeleteUserContext);
  async function onClickShowUser() {
    try {
      const response = await getUser(objectItem.id);
      setStateViewUser(response.data);
      console.log(response.data);
      setViewUser(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function onClickEditUser(params) {
    try {
      const response = await getUser(objectItem.id);
      console.log(response.data);
      setStateUser(response.data);
      setEditModalUser(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function onClickDeleteUser() {
    setUserDelete({ id: objectItem.id, name: objectItem.name });
    setModalDeleteUser(true);
  }

  return (
    <tr>
      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
        {objectItem.name}
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        
        <select
        
          id="view_roles"
          class="block w-full p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected>Ver Roles</option>
          {objectItem.roles.map((rol)=>(<option disabled value={rol.id}>{rol.name}</option>))}
          
        </select>
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        {objectItem.email}
      </td>
      <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
        {objectItem.active?<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          ACTIVO
        </span>: <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">INACTIVO</span>}

       
      </td>

      <td className="p-4 whitespace-nowrap">
        <Dropdown label="Opciones" size="xs" width="3rem" color="dark">
          <Dropdown.Item icon={HiViewGrid} onClick={onClickShowUser}>
            Ver
          </Dropdown.Item>
          <Dropdown.Item icon={HiCog} onClick={onClickEditUser}>
            Editar
          </Dropdown.Item>
          <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickDeleteUser}>
            Eliminar
          </Dropdown.Item>
        </Dropdown>
      </td>
    </tr>
  );
}

export default ItemUser;
