
import React, { useState } from 'react'



function useInitialStateDeleteUser() {

    const [showModalDeleteUser,setModalDeleteUser]=useState(false)
    const [userToDelete,setUserDelete]=useState({id:"",nameUser:""})

  return {showModalDeleteUser,setModalDeleteUser,userToDelete,setUserDelete}

}

export default useInitialStateDeleteUser