import React, { useContext, useEffect, useMemo, useState } from "react";
import { useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import ItemProduct from "./ItemProduct";
import { Avatar, Button, Dropdown } from "flowbite-react";

import { HiViewGrid, HiCog, HiCurrencyDollar } from "react-icons/hi";
import { getProduct } from "./service";
import ModalEditProductContext from "../../context/Products/ModalEditProductContext";
import ModalDeleteProductContext from "../../context/Products/ModalDeleteProductContext";
import ModalCreateProduct from "./ModalCreateProduct";
import ModalEditProduct from "./ModalEditProduct";
import AlertError from "../../common/AlertError";
import ModalDeleteProduct from "./ModalDeleteProduct";



function TableProducts({
  data,
  fetchData,
  pageCount: controlledPageCount,
  metaData,
  ...props
}) {


  const columns = useMemo(
    () => [
      {
        id: "n_order",
        Header: "N°",
        accessor: "n",
      },
      {
        id: "image_product",
        Header: "Imagen",
        accessor: (row) => (
          <Avatar img={row.image ? row.image : "/imageDefaultProduct.png"} />
        ),
      },
      {
        id: "name_product",
        Header: "Nombre",
        accessor: (row) => (row.name ? row.name : null),
      },
      {
        id: "description_product",
        Header: "Descripcion",
        accessor: (row) => (row.description ? row.description : null),
      },
      {
        id: "category_product",
        Header: "Categoria",
        accessor: (row) =>
          props.categoryList.find(
            (category) => category.value == row.category_id
          )?.label || null,
      },

      {
        id: "unit_product",
        Header: "Unidad",
        accessor: (row) =>
          props.unitMeasurementsList.find(
            (unit) => unit.code == row.unit_measurement
          )?.label || null,
      },
      {
        id: "options",
        Header: "Acciones",
        Cell: ({ row: { original } }) => (
          <Dropdown label="Opciones" size="xs" width="3rem" color="dark">
            <Dropdown.Item
              icon={HiCog}
              onClick={() => onClickShowModalEdit(original)}
            >
              Editar
            </Dropdown.Item>
            <Dropdown.Item
              icon={HiCurrencyDollar}
              onClick={() => onClickDeleteProduct(original)}
            >
              Anular
            </Dropdown.Item>
          </Dropdown>
        ),
      },
    ],
    [props.categoryList, props.unitMeasurementsList]
  );

  const {
    showModalEditProduct,
    setShowModalEditProduct,
    productUpdate,
    setProductUpdate,
  } = useContext(ModalEditProductContext);

  const [showAlert, setAlert] = useState(null);
  const {
    showModalDelete,
    setModalDelete,
    productToDelete,
    setProductToDelete,
  } = useContext(ModalDeleteProductContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useReactTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize:10 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    getPaginationRowModel
  );

  async function onClickShowModalEdit(objectItem) {

    try {
      const response = await getProduct(objectItem.id);
      setProductUpdate(response.data);
      setShowModalEditProduct(true);
    } catch (error) {
      setAlert(<AlertError message={error.message} setState={setAlert} ></AlertError>)
    }
    
  }

  function onClickDeleteProduct(objectItem) {
    setProductToDelete(objectItem);
    setModalDelete(true);
    
  }



  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          

              <ModalCreateProduct unitMeasurementsList={props.unitMeasurementsList} fetchData={fetchData} categoryList={props.categoryList} gotoPage={gotoPage}  ><Button size="sm">Crear Nuevo</Button> </ModalCreateProduct>
              <ModalEditProduct  gotoPage={gotoPage} categoryList={props.categoryList} unitMeasurementsList={props.unitMeasurementsList} fetchData={fetchData}></ModalEditProduct>
        
            <ModalDeleteProduct fetchData={fetchData} gotoPage={gotoPage} setAlert={setAlert}  ></ModalDeleteProduct>
            
            
        </div>
        <div>{showAlert}</div>
      </div>

      <div className="flex flex-col mt-6">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-x-hidden shadow sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y text-left text-sm text-gray-500 divide-gray-200 dark:divide-gray-600"
              >
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          class="px-6 py-4"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td className="px-4 py-3" {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center flex-col gap-3 mt-3">
        <span class="text-sm text-gray-700 dark:text-gray-400">
          Pagina{" "}
          <span class="font-semibold text-gray-900 dark:text-white">
            {pageIndex + 1}
          </span>
          <span class="font-semibold text-gray-900 dark:text-white">{}</span> de{" "}
          <span class="font-semibold text-gray-900 dark:text-white">
            {pageOptions.length}
          </span>
        </span>
        <div className="flex items-center justify-center">
          <button
            className=" disabled:opacity-50  inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Atras
          </button>
          <button
            className=" disabled:opacity-50  inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Siguiente
            <svg
              aria-hidden="true"
              class="w-5 h-5 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableProducts;
