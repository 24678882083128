
import React, { useEffect, useState } from 'react'
import ModalCreateProvider from '../components/client_providers/ModalCreateProvider';
import { Avatar, Dropdown,Button } from "flowbite-react";

import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import { getProviders } from '../components/client_providers/service';
import useInitialDeleteProvider from '../hooks/Providers/useInitialDeleteProvider';
import ModalDeleteProvider from '../components/client_providers/ModalDeleteProvider';
import ModalDeleteProviderContext from '../context/Providers/ModalDeleteProvider';
import ItemProvider from '../components/client_providers/ItemProvider';
import { handleGetProviders } from '../common/Expense_Purchase_handlers';




function Providers() {

  const [providers,setProviders]=useState([]);
  const useDeleteProvider=useInitialDeleteProvider()
  const [showAlert,setAlert]=useState(null)

  

useEffect(()=>{
  handleGetProviders(setProviders)
},[])

  return (
    <ModalDeleteProviderContext.Provider value={useDeleteProvider}>
      <div>
    <h2 className="text-3xl font-extrabold dark:text-white mb-6">
      <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
        Proveedores
      </small>
    </h2>
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          <ModalCreateProvider  setProvidersList={setProviders}></ModalCreateProvider>
        </div>
        <ModalDeleteProvider showAlert={showAlert} setProvidersList={setProviders} setAlert={setAlert}></ModalDeleteProvider>
        <div>
          {showAlert}
        </div>
      </div>

      <div className="flex flex-col mt-6">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                    >
                      RUC/DNI
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                    >
                      Razon Social
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                    >
                      Tipo Doc.
                    </th>
                    
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                  {providers.map((provider)=>(<ItemProvider key={provider.id} objectItem={provider}></ItemProvider>))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between pt-3 sm:pt-6">
       
      </div>
    </div>
  </div>
    </ModalDeleteProviderContext.Provider>
  )
}

export default Providers