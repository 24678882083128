import React, { useRef } from "react";
import { useState } from "react";
import {
  Button,
  Modal,
  Label,
  TextInput,
  Checkbox,
  Spinner,
} from "flowbite-react";
import { createRol } from "./service";
import AlertSuccess from "../../common/AlertSuccess";
import AlertError from "../../common/AlertError";
import MultiSelect from "../../utils/MultiSelect";

function ModalCreateRol(props) {
  const [show, setShow] = useState(false);
  const form_create_rol = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showAlert, setAlert] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const url = "/api/permissions?q=all";
  function handleClick() {
    setShow(true);
    form_create_rol.current.reset();
  }

  function handleClose() {
    setShow(false);
    setAlert(null);
  }

  async function handleSubmitRol(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsDisabled(true);
    setLoading(true);
    setAlert(null);
    const formData = new FormData(form_create_rol.current);

    const newRol = {
      name: formData.get("name"),
      permissions: permissions.map((item) => item.value),
    };

   

    try {
      const response = await createRol(newRol);
      if (response) {
        if (response.status == "success") {
          setLoading(false);
          setAlert(
            <AlertSuccess
              message={response.message}
              setState={setAlert}
            ></AlertSuccess>
          );
          setIsDisabled(false);
          form_create_rol.current.reset();
          props.gotoPage(0)
          props.fetchData({ pageIndex:0, pageSize:10 })
        }
      }
    } catch (error) {
      setLoading(false);
      setIsDisabled(false);
      setAlert(
        <AlertError message={error.message} setState={setAlert}></AlertError>
      );
    }

    /*
      setAlert(<AlertSuccess message={"hello world"} setState= {setAlert}></AlertSuccess>)
    setIsDisabled(false);
    setLoading(false);
    form_create_permission.current.reset();
    
    */
  }

  return (
    <React.Fragment>
      <div>
        <Button onClick={handleClick}>Crear Nuevo</Button>
        <Modal show={show} onClose={handleClose}>
          <form ref={form_create_rol} onSubmit={handleSubmitRol}>
            <Modal.Header>Crear Nuevo Rol</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        for="name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Nombre
                      </label>
                      <input
                        type="text"
                        id="name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Administrador"
                        required
                        name="name"
                      />
                    </div>
                  </div>
                  <div class="mb-6">
                    <label
                      htmlFor="permissions"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Permisos
                    </label>
                    <MultiSelect
                      
                      stateAlert={{ showAlert, setAlert }}
                      stateValue={{ permissions, setPermissions }}
                    ></MultiSelect>
                  </div>
                </div>
              <div className="mt-5">
              {isLoading ? (
                <div className="w-full flex justify-center items-center">
                  <Button color="gray">
                    <Spinner aria-label="Alternate spinner button example" />
                    <span className="pl-3">Cargando...</span>
                  </Button>
                </div>
              ) : null }
              </div>
              <div className="mt-6">{showAlert}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={isDisabled}>
                Guardar
              </Button>
              <Button color="gray" onClick={handleClose} disabled={isDisabled}>
                Cerrar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default ModalCreateRol;
