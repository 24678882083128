
import { Avatar, Dropdown, Navbar,DarkThemeToggle } from 'flowbite-react'
import React, { useContext, useEffect, useState } from 'react'
import AuthButton from './auth/AuthButton'

import { getMe } from './auth/service'




function NavbarElement() {

  const [stateUser,setStateUser]=useState({
    name:"",
    email:"",
    roles : []
})

  async function setDataUserLoged() {
      
    const response = await getMe()
    setStateUser(response)
}

  useEffect(()=>{

    // Check if user data exists in local storage
    const userData = localStorage.getItem('user_logged_adm');
   
    if (userData) {
     
     try {
      const data=JSON.parse(userData)
    
      setStateUser(data);
     } catch (error) {
      console.error(error)
     }
      
    }

  },[])


  return (
    
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <Navbar fluid={true} rounded={true}>
  <Navbar.Brand>
    <img
      src="../../logo-corpcasas.png"
      className="mr-3 h-6 sm:h-9"
      alt="Flowbite Logo"
    />
    <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
      CorpCasas
    </span>
  </Navbar.Brand>
 
  <div className="flex md:order-2">
    <Dropdown
      arrowIcon={false}
      inline={true}
      label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded={true}/>}
    >
      <Dropdown.Header>
        <span className="block text-sm">
          {stateUser.name +" "+ stateUser.last_name}
        </span>
        <span className="block truncate text-sm font-medium">
          { stateUser.email }
        </span>
      </Dropdown.Header>
      <Dropdown.Item>
        Mi perfil
      </Dropdown.Item>

      <Dropdown.Divider />
      <Dropdown.Item>
        <AuthButton></AuthButton>
      </Dropdown.Item>
    </Dropdown>
    <Navbar.Toggle />
  </div>
  
</Navbar>
    </nav>
     
    
  )
}

export default NavbarElement