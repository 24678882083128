import { Alert } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import AsyncSelect from "react-select/async";

import AlertError from "../../common/AlertError";
import { getPermissions } from "../permissions/service";
import { getRoles } from "./service";
import ModalEditRoleContext from "../../context/ModalEditRoleContext";

function MultiSelecEditRole(props) {
  const { setData, data } = useContext(ModalEditRoleContext);
  const [permissions, setPermissions] = useState();

  function handleSelect(selectedOptions) {
    setData({ ...data, permissions: selectedOptions });
  }

  async function getPermissionsList() {
    try {
      const response = await getPermissions("?q=all");
      const data = response.data.map((item) => ({
        value: Number(item.id),
        label: item.name,
      }));
      setPermissions(data);
    } catch (error) {
      throw new Error("algo salio mal");
    }
  }

  useEffect(() => {
    getPermissionsList();
  }, []);

  return (
    <Select
      isMulti
      value={data.permissions}
      options={permissions}
      onChange={handleSelect}
    />
  );
}

export default MultiSelecEditRole;
