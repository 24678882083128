
import React, { useState } from 'react'
import FormRegisterExpenses from '../components/FormRegisterExpenses'
import RegisterExpenseContext from '../context/RegisterExpenseContext'
import useInitialStateExpense from '../hooks/useInitialStateExpense'



function RegisterExpenses() {
  const initialStateExpense=useInitialStateExpense()
  const [showAlert,setAlert]=useState(null)
  return (
    <RegisterExpenseContext.Provider value={initialStateExpense}>
      <div>
        <div className="flex md:flex-row flex-col gap-4 md:justify-between">
        <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Registro de Gasto</h2>
        <div>
        { showAlert }
        </div>
        </div>
        
        <FormRegisterExpenses setAlert={setAlert}></FormRegisterExpenses>
       
    </div>
    </RegisterExpenseContext.Provider>
    
  )
}



export default RegisterExpenses
