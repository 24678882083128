

import React from 'react'
import { Outlet } from 'react-router-dom';

import NavbarComponent from "./NavbarElement";
import SidebarComponent from "./SidebarComponent";

function Layout() {
  return (
    <div>
        <NavbarComponent></NavbarComponent>
        <SidebarComponent></SidebarComponent>
        <div className="p-4 sm:ml-64  dark:bg-gray-700">
    <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
       
       <Outlet></Outlet>
       
    </div>
    
<footer className=" bg-white rounded-lg shadow m-4 dark:bg-gray-800">
    <div className="w-full mx-auto container md:p-6 p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 made with ❤️ by  <a  className="hover:underline">Corpcasas</a>. Todos los derechos reservados.
    </span>
    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a  className="mr-4 hover:underline md:mr-6 ">Si algo falla llamar a Ricardo</a>
        </li>
        
       
    </ul>
    </div>
</footer>

 </div>
 
    </div>
  )
}

export default Layout