
import React, { useContext, useState } from 'react'
import { Dropdown } from "flowbite-react";
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalViewRoleContext from '../../context/ModalViewRoleContext';
import ModalEditRoleContext from '../../context/ModalEditRoleContext';
import { getRole } from './service';
import ModalDeleteContext from '../../context/ModalDeleteRoleContext';




function ItemRol({objectItem}) {

  const [state,setState]=useState({...objectItem})
 const {stateView,openModal,loadData}=useContext(ModalViewRoleContext)
 const  {showModal,setShowModal,setData}=useContext(ModalEditRoleContext)
 const {showModalDelete,setShowModalDelete,role,setRole}=useContext(ModalDeleteContext)
 
 function handleView(event) {
    
    openModal()
    loadData(state.id)
  }

  function showModalDeleteRole(objectItem) {
    setShowModalDelete(true)
    setRole({...role,nameRole:objectItem.name,idRole:objectItem.id})
  }
  

  async function handleEdit() {
    
    
    const response=await getRole(objectItem.id)
    
    const data={idRole:response.data.id,name:response.data.name , permissions:response.data.permissions.map((item)=>({value:Number(item.id),label:item.name}))}
    
    setData(data)
    setShowModal(true)


  }

  

  return (
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    <th
      scope="row"
      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {objectItem.id}
    </th>
    <td class="px-6 py-4">{objectItem.name}</td>
    <td class="px-6 py-4"><Dropdown
                          label="Opciones"
                          size="xs"
                          width="3rem"
                          color="dark"
                        >
                          
                          <Dropdown.Item icon={HiViewGrid} onClick={handleView}>
                            Ver
                          </Dropdown.Item>
                          <Dropdown.Item icon={HiCog} onClick={handleEdit}>Editar</Dropdown.Item>
                          <Dropdown.Item icon={HiCurrencyDollar} onClick={showModalDeleteRole}>
                            Eliminar
                          </Dropdown.Item>
                          
                        </Dropdown></td>

  </tr>
  )
}

export default ItemRol