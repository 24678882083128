import client, {
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "../../api/client.js";

export const getPerson = async (personToSearch) => {
  try {
    const response = await client.get(
      `/api/sunat/search?type_doc=${personToSearch.type_document}&identification_document=${personToSearch.number}`
    );

    return response;
  } catch (error) {
    console.log(error);

    throw new Error(error.message);
  }
};

export const createProvider = async (provider) => {
  try {
    const response = await client.post("/api/suppliers", provider);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProviders = async (customParams) => {
  try {
    const response = await client.get("/api/suppliers", {
      params: customParams,
    });
    
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteProvider = async (provider) => {
  try {
    const response = await client.delete(`/api/suppliers/${provider.id}`);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};
