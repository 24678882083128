import { useState } from "react";



const initialState={
    name:"",
    email:"",
    roles : []
}




function useInitialStateUserLoged() {

    const [stateUser,setStateUser]=useState(initialState);
    


  return {stateUser,setStateUser}
}

export default useInitialStateUserLoged