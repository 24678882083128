

import React, { useState } from 'react'
import { useEffect } from 'react';
import ItemCompany from '../components/companies/ItemCompany';
import ModalCreateCompany from '../components/companies/ModalCreateCompany';
import ModalDeleteCompany from '../components/companies/ModalDeleteCompany';
import ModalEditCompany from '../components/companies/ModalEditCompany';
import { getCompanies } from '../components/companies/service';
import ModalDeleteCompanyContext from '../context/Company/ModalDeleteCompanyContext';
import ModalEditCompanyContext from '../context/Company/ModalEditCompanyContext';
import useInitialStateDeleteCompany from '../hooks/companies/useInitialStateDeleteCompany';
import useInitialStateEditCompany from '../hooks/companies/useInitialStateEditCompany';




function Companies() {
    const [providers,setProviders]=useState([]);
    const [showAlert,setAlert]=useState(null)
    const initialStateEditCompany=useInitialStateEditCompany()
    const initialStateDeleteCompany=useInitialStateDeleteCompany()
    const [companiesList,setCompaniesList]=useState([])

    async function getCompaniesList() {
      try {
        const response=await getCompanies()
        setCompaniesList(response.data)
      } catch (error) {
        console.log(error)
      }
    }


    useEffect(()=>{
      getCompaniesList()
    },[])

  return <ModalDeleteCompanyContext.Provider value={initialStateDeleteCompany}>
    <ModalEditCompanyContext.Provider value={initialStateEditCompany}>
    <div>
  <h2 className="text-3xl font-extrabold dark:text-white mb-6">
    <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
      Empresas
    </small>
  </h2>
  <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <div className="items-center justify-between lg:flex">
      <div className="mb-4 lg:mb-0">
        <ModalCreateCompany getCompaniesList={getCompaniesList} ></ModalCreateCompany>
        <ModalEditCompany getCompaniesList={getCompaniesList}></ModalEditCompany>
        <ModalDeleteCompany getCompaniesList={getCompaniesList} setAlert={setAlert} ></ModalDeleteCompany>
      </div>
      
      <div>
        {showAlert}
      </div>
    </div>

    <div className="flex flex-col mt-6">
      <div className="overflow-x-auto rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    scope="col"
                    className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                  >
                    RUC/DNI
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                  >
                    Direccion
                  </th>
                  
                  <th
                    scope="col"
                    className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800">
                {companiesList.map((company)=><ItemCompany key={company.id} objectItem={company}></ItemCompany>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className="flex items-center justify-between pt-3 sm:pt-6">
     
    </div>
  </div>
</div>
  </ModalEditCompanyContext.Provider>
  </ModalDeleteCompanyContext.Provider>
  
  
  
}

export default Companies