
import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";


export const getUnitMesuarement=async ()=>{

    try {
        const response= await client.get("/api/sunat/unit-measurement")
        return response

    } catch (error) {

        throw new Error(error.message)
    }
}


export const createProduct=async (newProduct)=>{
    try {

        const response= await client.post("/api/products",newProduct,{ headers: {'Content-Type': 'multipart/form-data'
            }
          })

          return response
        
    } catch (error) {
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}


export const  getProducts=async (customParams)=>{

    try {
        const response=await client.get(`/api/products`,{params:customParams})
        return response
    } catch (error) {
        throw new Error(error.message)
    }

}



export const getProduct=async(idProduct)=>{
    try {
        const response= await client.get(`/api/products/${idProduct}`)
        return response
    } catch (error) {
        throw new Error(error.message)
    }
}



export const updateProduct= async (idProduct,updatedProduct)=>{
    try {
        const response= await client.post(`/api/products/${idProduct}`,updatedProduct,{ headers: {'Content-Type': 'multipart/form-data'
    }
  })

  return response
    } catch (error) {
        console.log("hubo error")
        console.log(error)
        throw new Error(error.message)

    }
}


export const deleteProduct=async (product)=>{
    try {

        const response= client.delete(`/api/products/${product.id}`)
        return response

    } catch (error) {

        throw new Error(error.message)

    }
}