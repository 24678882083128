import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";
import { storage } from "../../utils/storage.js";

const rolesBaseUrl="/api/roles"

export const getRoles = async (params)=>{
    try {
        const response = await client.get(`${rolesBaseUrl}${params}`);
        return response
    } catch (error) {

        if (error.errors) {
            const message=error.errors.name.join("-")
            throw new Error(message)
        } else {
            throw new Error(error.message)
        }
    }
    
}

export const createRol = async (newRole)=>{
    try {
        const response =await client.post(rolesBaseUrl,newRole)
        return response
    } catch (error) {
        console.log(error)
        if (error.errors) {
            const message=error.errors.name.join("-")
            throw new Error(message)
        } else {
            throw new Error(error.message)
        }
        
    }
    

 }


 export const getRole = async (idRole)=>{
    try {
        const response =await client.get(`${rolesBaseUrl}/${idRole}`)
        return response
    } catch (error) {
        if (error.errors) {
            const message=error.errors.name.join("-")
            throw new Error(message)
        } else {
            throw new Error(error.message)
        }
        
    }
    

 }

 export const updateRole = async (role)=>{
    try {
        const response =await client.put(`${rolesBaseUrl}/${role.id}`,role)
        return response
    } catch (error) {
        if (error.errors) {
            const message=error.errors.name.join("-")
            throw new Error(message)
        } else {
            throw new Error(error.message)
        }
        
    }
    

 }


export const deleteRoles=async (idRole)=>{
    try {
        const response=await client.delete(`${rolesBaseUrl}/${idRole}`)
        return response
    } catch (error) {
        if (error.errors) {
            const message=error.errors.name.join("-")
            throw new Error(message)
        } else {
            throw new Error(error.message)
        }
    }

}