
import  { useState } from 'react'

const initialState = { id:"", ruc:"" , name:"", address:""}



function useInitialStateDeleteCompany() {

    const [showModalDelete,setShowModalDelete]=useState(false);
    const [companyToDelete,setCompanyToDelete]=useState(initialState)

  return {showModalDelete,setShowModalDelete,companyToDelete,setCompanyToDelete}

}



export default useInitialStateDeleteCompany