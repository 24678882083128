

import { Dropdown } from 'flowbite-react'
import React, { useContext } from 'react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import { handleBadgeStatePurchase } from '../../common/Expense_Purchase_handlers';
import ModalViewExpenseContext from '../../context/Expenses/ModalViewExpenseContext';
import { Link } from 'react-router-dom';



function ItemListExpense({objectItem,type_documents_list,providers_list}) {

  const {state,setState,showModalView,setModalView}=useContext(ModalViewExpenseContext)


  function onClickViewExpense() {

    setState(objectItem)
    setModalView(true)

  }


      


  return (
    <tr>
    <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
      {objectItem.type_document_payment?.description || null}
    </td>
    <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
      {objectItem.serie}
    </td>
    <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
      {objectItem.correlative}
    </td>
    <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
      {objectItem.date_expense}
    </td>
    <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
      {objectItem.supplier_identification_document?objectItem.supplier_identification_document:null}
    </td>
    <td className="p-4 whitespace-nowrap">
      {objectItem.sub_total}
    </td>
    <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
      {objectItem.total_igv}
    </td>
    <td className="p-4 whitespace-nowrap">
    
    {handleBadgeStatePurchase(objectItem.status)}
    </td>

    <td className="p-4 whitespace-nowrap">
     { objectItem.total}
    </td>

    <td className="p-4 whitespace-nowrap">
    <Dropdown
        label="Opciones"
        size="xs"
        width="3rem"
        color="dark"
      >
        
        <Dropdown.Item icon={HiViewGrid} onClick={onClickViewExpense}>
          Ver
        </Dropdown.Item>
        <Dropdown.Item icon={HiCog}><Link to={`/edit_expense/${objectItem.id}`}>Editar</Link></Dropdown.Item>
        <Dropdown.Item icon={HiCurrencyDollar} >
          Eliminar
        </Dropdown.Item>
        
      </Dropdown>
    </td>
    
  </tr>
  )
}

export default ItemListExpense