

import React, { useState } from 'react'

function useInitialDeleteProvider() {

    const [showModalDelete,setShowModalDelete]=useState(false)
    const [providerToDelete,setProviderDelete]=useState({idProvider:"",nameProvider:""})

  return {showModalDelete,setShowModalDelete,providerToDelete,setProviderDelete}
}

export default useInitialDeleteProvider