export function removeEmptyOrNullParams(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }

  export function getDateFromValue(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    
   
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    
    // Format the date as desired (e.g., "yyyy-mm-dd")
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    return formattedDate;
  }