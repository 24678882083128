
import React, { useState } from 'react'


const initialState={id:"",name:""}
function useInitialDeleteProduct() {

    const [showModalDelete,setModalDelete]=useState(false);
    const [productToDelete,setProductToDelete]=useState(initialState) 

  return {showModalDelete,setModalDelete,productToDelete,setProductToDelete}
}

export default useInitialDeleteProduct