

import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import EditPurchaseContext from '../../context/EditPurchaseContext'
import { getProviders } from '../client_providers/service'
import { getCompanies } from '../companies/service'
import { getTypeDocumentsPayments } from './service'
import ModalCreateProvider from '../client_providers/ModalCreateProvider'
import Select from "react-select";
import BasicDatePicker from '../BasicDatePicker'
import PurchasesListItems from './PurchasesListItems'

import PurchaseContext from '../../context/PurchaseContext'
import PurchaseListItemToUpdate from './PurchaseListItemToUpdate'
import ModalPreviewUpdatePurchase from './ModalPreviewUpdatePurchase'
import { Link } from 'react-router-dom'
import AlertError from '../../common/AlertError'
import { getUserList , getProvidersList } from '../../common/Expense_Purchase_handlers'




function FormEditPurchase(props) {

  const { state, setState } = useContext(PurchaseContext)
  const { detailItems } = state
  const [providersList, setProvidersList] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [typeDocumentsPaymentList, setTypeDocumentsList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const formPurchase = useRef()
  const [usersList, setUserList] = useState([])
  const type_payments = [
    { value: "YAPE", name: "YAPE", },
    { value: "EFECTIVO", name: "EFECTIVO" },
    { value: "DEPOSITO", name: "DEPOSITO" },
    { value: "TARJETA", name: "TARJETA" },
    { value: "PLIN", name: "PLIN" }

  ]

  const statuses = [{ id: "PENDIENTE", name: "PENDIENTE" },
  { id: "OBSERVADO", name: "OBSERVADO" },
  { id: "REVISADO", name: "REVISADO" }
  ]


  function handleChangeState(event) {
    setState({ ...state, status: event.target.value })
  }


  function onChangeTypeVoucher(event) {

    event.stopPropagation();

    setState({ ...state, voucher: { ...state.voucher, type: { id: event.target.value, name: event.target.options[event.target.selectedIndex].text } } })

  }



  function onChangeDatePurchase(value) {

    const date = new Date(value)

    setState({ ...state, date_purchase: date })

  }

  function onChangeSelectProvider(provider) {

    if (provider) {
      setState({ ...state, provider: { number: provider.value, name: provider.label, id: provider.id } })

    } else {
      setState({ ...state, provider: provider })

    }

  }




  function onChangeSelectTypePayment(event) {
    event.stopPropagation();
    setState({ ...state, type_payment: { id: event.target.value, name: event.target.options[event.target.selectedIndex].text } })
  }

  async function handleGetProviders(customParams) {
    try {
      const response = await getProviders(customParams)
      const providers = response.data.map((provider) => ({
        value: provider.identification_document,
        label: provider.name,         
        id: provider.id
      }));
      setProvidersList(providers)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  function handleSumTotals() {

    const reducer_igv = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.igv)
    const sum_igv = state.detailItems.reduce(reducer_igv, 0)

    const reducer_total = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalItemPurchase)
    const sum_total = state.detailItems.reduce(reducer_total, 0)

    setState({ ...state, igv: Number(sum_igv).toFixed(2), total: Number(sum_total).toFixed(1), subTotal: Number(sum_total).toFixed(2) - Number(sum_igv).toFixed(2) })

  }

  function onChangeSelectCompany(event) {
    setState({ ...state, company: { id: event.target.value, name: event.target.options[event.target.selectedIndex].text } })
  }


  async function getCompaniesList() {
    try {
      const response = await getCompanies()
      setCompaniesList(response.data)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  async function getTypeDocumentPaymentList() {
    try {
      const response = await getTypeDocumentsPayments()

      setTypeDocumentsList(response.data)


    } catch (error) {
      throw new Error(error.message)
    }
  }

  function handleCleanFields() {

    formPurchase.current.reset()
    setState({ ...state, detailItems: [] })
  }

  function handleChangeVoucherInputs(event) {

    event.stopPropagation()
    setState({ ...state, voucher: { ...state.voucher, [event.target.name]: event.target.value.toUpperCase(), } })

  }


  function onChangeSelectResponsible(payload) {
    if (payload) {
      setState({ ...state, responsible: { id: Number(payload.value), name: payload.label } })
    } else {
      setState({ ...state, responsible: { id: "", name: "" } })
    }
  }




  useEffect(() => {

    //buscar el comprobante de a compra seleccionada
    const typeVoucher = typeDocumentsPaymentList.find((type) => state.voucher.type.name == type.description)

    if (typeVoucher) {

      setState({ ...state, voucher: { ...state.voucher, type: { id: typeVoucher.id, name: typeVoucher.description } } })
    }


    //buscar la empresa de la compra seleccionada

    const companyFound = companiesList.find((company) => state.company.name == company.name)

    if (companyFound) {
      setState({ ...state, company: companyFound })
    }


    handleSumTotals()


  }, [detailItems, providersList])

  useEffect(() => {


    try {

      getUserList(setUserList,{q:"all"})
      getProvidersList(setProvidersList,{q:"all"})
      getCompaniesList()
      getTypeDocumentPaymentList()
    } catch (error) {
      props.setAlert(<AlertError message={error.message} setState={props.setAlert}></AlertError>)
    }



  }, [])


  return (
    <div>
      <form ref={formPurchase}>
        <div class="p-2  mb-4  bg-white gap-4 border grid border-gray-200 rounded-lg shadow-sm md:grid-cols-2 dark:border-gray-700 sm:p-2 dark:bg-gray-800">
          <div class="p-3 space-y-6  bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-3 sm:pt-4 dark:bg-gray-800">
            <div class="mb-4 flex items-end gap-5">
              <div className="w-full">
                <label
                  htmlFor="provider_purchase"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Proveedor :
                </label>
                <Select

                  className="basic-single z-10"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="providers"
                  options={providersList.map((provider)=>{
                    return {value:provider.number , label:provider.name, id:provider.id}
                  })}
                  menuPosition="fixed"
                  onChange={onChangeSelectProvider}
                  value={state.provider ? { ...state.provider, value: state.provider.number, label: state.provider.name, id:state.provider.id} : null}

                />
              </div>
              <ModalCreateProvider setProvidersList={setProvidersList}></ModalCreateProvider>
            </div>
            <div class="grid gap-4 mb-4 md:grid-cols-2">
              <div>
                <label
                  htmlFor="first_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Fecha de compra
                </label>

                <div class="relative max-w-sm">
                  <div class="absolute z-[1] inset-y-0 z-20 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <BasicDatePicker defaultDate={state.date_purchase} onChangeDate={onChangeDatePurchase}></BasicDatePicker>
                </div>
              </div>

              <div>
                <label
                  htmlFor="company"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Empresa :
                </label>
                <select
                  onChange={onChangeSelectCompany}
                  id="company"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="company"
                >
                  <option value="">Selecciona</option>

                  {companiesList.map((company) => (<option selected={company.name == state.company.name ? true : false} value={company.id}>{company.name}</option>))}

                </select>
              </div>
              <div>
                <label
                  htmlFor="type_payment"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tipo de pago
                </label>
                <select
                  onChange={onChangeSelectTypePayment}
                  name="type_payment"
                  id="type_payment"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Selecciona</option>
                  {type_payments.map((type) => (<option selected={type.value == state.type_payment.id ? true : false} value={type.value}>{type.name}</option>))}

                </select>
              </div>
              <div>
                <label for="state_purchase" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Estado</label>
                <select id="state_purchase" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name='status' onChange={handleChangeState}>
                  <option selected value={null}>Seleccione</option>
                  {statuses.map((item) => (<option selected={item.id == state.status ? true : false} value={item.id}>{item.name}</option>))}


                </select>
              </div>
            </div>
          </div>

          <div class="p-4  bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div class="mb-6 flex flex-col gap-4">
              <div>
                <label
                  htmlFor="type_voucher"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tipo de Comprobante
                </label>
                <select

                  onChange={onChangeTypeVoucher}
                  id="type_voucher"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="type_voucher"
                >
                  <option value="">Selecciona</option>
                  {typeDocumentsPaymentList.map((item) => (<option selected={item.description == state.voucher.type.name ? true : false} value={item.id}>{item.description}</option>))}



                </select>
              </div>
              <div class="grid gap-6 mb-2 md:grid-cols-2" onChange={handleChangeVoucherInputs}>
                <div>
                  <label
                    htmlFor="serie"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Serie
                  </label>
                  <input
                    type="text"
                    id="serie"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="BB01"
                    required
                    maxLength="4"
                    name="serie"
                    value={state.serie}
                  />
                </div>
                <div>
                  <label
                    htmlFor="correlative"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Correlativo
                  </label>
                  <input
                    type="text"
                    id="correlative"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="00000003452"
                    required
                    maxLength="8"
                    name="correlative"
                    value={state.correlative}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="responsible"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Responsable
                </label>
                <Select
                  className="basic-single z-10"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="responsible"
                  options={usersList.map((user) => ({ value: user.id, label: user.name }))}
                  menuPosition="fixed"
                  value={state.responsible?{value:state.responsible.id,label:state.responsible.name}:null}
                  onChange={onChangeSelectResponsible}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="p-4 mb-4  bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div class="relative overflow-x-auto ">
          <PurchaseListItemToUpdate></PurchaseListItemToUpdate>
        </div>
        <div className="grid w-full ">
          <div class="mb-4">
            <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      Subtotal :
                    </p>

                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.subTotal}
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      IGV :
                    </p>

                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.igv}
                  </div>
                </div>
              </li>

              <li class="py-2">
                <div class="flex items-center justify-end gap-x-5">
                  <div class="">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                      Total :
                    </p>

                  </div>
                  <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    S/ {state.total}
                  </div>
                </div>
              </li>

            </ul>
          </div>
          <div className="flex  md:justify-end">
            <Link to="/list_purchases"><button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Ver Compras</button></Link>
            <ModalPreviewUpdatePurchase providersList={providersList} showModal={showModal} setShowModal={setShowModal} typeDocumentsPaymentList={typeDocumentsPaymentList} handleCleanFields={handleCleanFields}></ModalPreviewUpdatePurchase >
          </div>
        </div>
      </div>
    </div>
  );
}


export default FormEditPurchase