import  { useState } from 'react'

const initialState = { id:"" , name:""}

function useInitialStateDeleteCategoryProduct() {

    const [showModalDelete,setShowModalDelete]=useState(false);
    const [categoryProductToDelete,setCategoryProductToDelete]=useState(initialState)

  return {showModalDelete,setShowModalDelete,categoryProductToDelete,setCategoryProductToDelete}
  
}

export default useInitialStateDeleteCategoryProduct