

import { Dropdown } from 'flowbite-react'
import React, { useContext, useEffect, useState } from 'react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalDeleteCompanyContext from '../../context/Company/ModalDeleteCompanyContext';
import ModalEditCompanyContext from '../../context/Company/ModalEditCompanyContext';



function ItemCompany({objectItem}) {

 const [stateCompany,seStateCompany]=useState(objectItem)
 const {showModalEdit,setShowModalEdit,company,setCompany}=useContext(ModalEditCompanyContext)
 const {showModalDelete,setShowModalDelete,companyToDelete,setCompanyToDelete}=useContext(ModalDeleteCompanyContext)

    async function onClickShowModalEdit() {

        setCompany(objectItem)

        setShowModalEdit(true)

    }

    function onClickShowModalDelete() {

        setCompanyToDelete(objectItem)
        setShowModalDelete(true)
    }




  return (
    <tr>
        <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
          {objectItem.identification_document}
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          {objectItem.name}
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          {objectItem.address}
        </td>
        
        <td className="p-4 whitespace-nowrap">
        <Dropdown
              label="Opciones"
              size="xs"
              width="3rem"
              color="dark"
            >
              <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickShowModalEdit}>
                Editar
              </Dropdown.Item>
              <Dropdown.Item icon={HiCurrencyDollar} onClick={onClickShowModalDelete}>
                Eliminar
              </Dropdown.Item>
              
            </Dropdown>
        </td>
      </tr>
  )
}

export default ItemCompany