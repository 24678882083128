import  { useState } from 'react'

const initialState = { name:"",unit_measurement:"",description:"",category_id:"",image:"", _method:"PUT"}

function useInitialStateEditProduct() {

    const [showModalEditProduct,setShowModalEditProduct]=useState(false);
    const [productUpdate,setProductUpdate]=useState(initialState)

  return {showModalEditProduct,setShowModalEditProduct,productUpdate,setProductUpdate}
  
}



export default useInitialStateEditProduct