import { useState } from "react";
import { getRole } from "../components/roles/service";


const initialState={
    name:"",permissions:[]
}




function useInitialStateViewRole() {

    const [stateViewRole,setStateViewRole]=useState(initialState);
    const [showViewModal,setShowViewModal]=useState(false)
    


  return {stateViewRole,setStateViewRole,showViewModal,setShowViewModal}
}

export default useInitialStateViewRole