import React, { useContext, useEffect, useState } from "react";
import ButtonDeleteItem from "../../common/ButtonDeleteItem";
import PurchaseContext from "../../context/PurchaseContext";
import SearchSelect from "../../utils/SearchSelect";
import PurchaseItem from "./PurchaseItem";
import { v4 as uuidv4 } from 'uuid';
import { getProducts, getUnitMesuarement } from "../products/service";
import { getCategoriesProduct } from "../products/Category/service";



function PurchaseListItemToUpdate() {
  const { state, addToListDetail, setState, removeFromList ,updateItemsDetail } =useContext(PurchaseContext);
  const { detailItems } = state;
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [unitMeasurementsList,setUnitsList]=useState([])


  async function getProductsList(customParams) {
    try {
      const response = await getProducts(customParams);
      const products = response.data.map((product) => ({
        value: product.id,
        label: product.name,
        id:product.id
      }));
      
      
      
      setProductList(products);

      
      
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  }


  async function getUnitList() {
    try {
      const response = await getUnitMesuarement();
      const unitMeasurements = response.data.map((unit) => ({
        value: unit.id,
        label: unit.name,
        code: unit.code_sunat,
      }));

      setUnitsList(unitMeasurements);

    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  }

  function addProductToList() {
    const initialValueItem = {
      uuid: uuidv4(),
      product: {id:"", name:""},
      descriptionItem: null,
      quantityProduct: 0,
      unit: {},
      typeIgv:{id:"",name:""},
      igv:0,
      totalItemPurchase: 0,
    };
    
    addToListDetail(initialValueItem);
  }

 

  async function getCategoriesProductList() {
    try {
      const response = await getCategoriesProduct();
      const categories = response.data.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryList(categories);
    } catch (error) {
      throw new Error(error.message);
    }
  }


  useEffect(()=>{

      getProductsList({q:"all"})
  
      getUnitList();
    
      getCategoriesProductList()

  },[])

 

  return (
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3">
            Producto
          </th>
          <th scope="col" class="px-6 py-3">
            Descripcion
          </th>
          <th scope="col" class="px-6 py-3">
            Cantidad
          </th>
          <th scope="col" class="px-6 py-3">
            Unidad
          </th>
          <th scope="col" class="px-6 py-3">
            Tipo Igv
          </th>
          <th scope="col" class="px-6 py-3">
            Importe
          </th>
          <th scope="col" class="px-6 py-3">
            IGV
          </th>
          
          <th scope="col" class="px-6 py-3">
            Acciones
          </th>
        </tr>
      </thead>
      <tbody>
        
        {detailItems.map((item) => (
          <PurchaseItem initialItem={item} key={item.uuid}  productList={productList} getProductsList={getProductsList} unitMeasurementsList={unitMeasurementsList }
          categoryList={categoryList}
          
          ></PurchaseItem>
        ))}

               
        
      </tbody>
      <tfoot>
        <tr class="font-semibold text-gray-900 dark:text-white">
          <th scope="row" class="px-6 py-3 text-base">
            <button
              type="button"
              class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 px-3 py-2 text-xs font-medium text-center inline-flex  rounded-lg items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
              onClick={addProductToList}
            >
              Agregar Producto
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  );
}

export default PurchaseListItemToUpdate;