

import React, { useContext, useMemo, useState } from 'react'
import ModalCreateRol from './ModalCreateRol'
import ModalViewRole from './ModalViewRole'
import { Dropdown } from 'flowbite-react'
import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalViewRoleContext from '../../context/ModalViewRoleContext';
import ModalEditRoleContext from '../../context/ModalEditRoleContext';
import ModalDeleteContext from '../../context/ModalDeleteRoleContext';

import { useReactTable,getPaginationRowModel  } from "@tanstack/react-table";
import { useEffect } from 'react';
import { getRole } from './service';
import ModalEditRole from './ModalEditRole';
import ModalDeleteRole from './ModalDeleteRole';


function TableRoles({data,
    fetchData,
    pageCount: controlledPageCount,
    metaData
  }) {
    const {stateViewRole,setStateViewRole,showViewModal,setShowViewModal}=useContext(ModalViewRoleContext)
 const  {showModal,setShowModal,setData}=useContext(ModalEditRoleContext)
 const {showModalDelete,setShowModalDelete,role,setRole}=useContext(ModalDeleteContext)
  const [showAlert,setAlert]=useState()
 const columns=useMemo(()=>[{
        Header:"N°",
        accessor:"n"
    
    },{
        Header:"NOmbre",
        accessor:"name"
    
    },
    {
        Header:"Acciones",
        Cell:({row : {original}})=><Dropdown
        label="Opciones"
        size="xs"
        width="3rem"
        color="dark"
      >
        
        <Dropdown.Item icon={HiViewGrid} onClick={()=>handleView(original)}>
          Ver
        </Dropdown.Item>
        <Dropdown.Item icon={HiCog} onClick={()=>handleEdit(original)}>Editar</Dropdown.Item>
        <Dropdown.Item icon={HiCurrencyDollar} onClick={()=>(showModalDeleteRole(original))} >
          Eliminar
        </Dropdown.Item>
        
      </Dropdown>
        
    },
    
   
    
    ],[])

    
    const tableInstance=useReactTable({columns,data,initialState: { pageIndex: 0,pageSize:10 } ,manualPagination: true,pageCount: controlledPageCount},getPaginationRowModel)
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
      } = tableInstance

      useEffect(() => {
          fetchData({ pageIndex, pageSize })
        }, [fetchData, pageIndex, pageSize])


    async function handleView(state) {
        const role=await handleGetRole(state.id)
        
        setShowViewModal(true)
        setStateViewRole(role)
      }

      function showModalDeleteRole(objectItem) {
        setShowModalDelete(true)
        setRole({...role,nameRole:objectItem.name,idRole:objectItem.id})
      }


    async function handleGetRole(id) {
        
        try {
        const response = await getRole(id)
        return response.data
        } catch (error) {
            
        }
    }


    async function handleEdit(objectItem) {
    
    
        const response=await getRole(objectItem.id)
        
        const data={idRole:response.data.id,name:response.data.name , permissions:response.data.permissions.map((item)=>({value:Number(item.id),label:item.name}))}
        
        setData(data)
        setShowModal(true)
    
    
      }
    

  return (
    <div className="px-0 md:p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
              <div className="items-center justify-between lg:flex">
                <div className="mb-4 lg:mb-0">
                
                <ModalCreateRol
                    
                    gotoPage={gotoPage}
                    fetchData={fetchData}
                  ></ModalCreateRol>
                  
                        
                  <ModalDeleteRole  setAlert={setAlert} gotoPage={gotoPage}
                    fetchData={fetchData} ></ModalDeleteRole>
                  
                  <ModalViewRole></ModalViewRole>
                
                
                        <ModalEditRole  gotoPage={gotoPage}
                    fetchData={fetchData}></ModalEditRole>
                  
                  
                  
                
                </div>
                
                <div>
                  <div className="w-full flex">{showAlert}</div>
                </div>
              </div>

              <div className="flex flex-col mt-6">
                <div className="overflow-x-auto rounded-lg">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow sm:rounded-lg">
                      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" >
            
            {headerGroups.map((headerGroup)=>(<tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
                <th scope="col" class="px-6 py-3" {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>))}
            
        </thead>
                         <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td class="px-6 py-4" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
                  </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex items-center justify-center flex-col gap-3 mt-3'>
<span class="text-sm text-gray-700 dark:text-gray-400">
      Pagina <span class="font-semibold text-gray-900 dark:text-white">{pageIndex+1}</span><span class="font-semibold text-gray-900 dark:text-white">{}</span> de <span class="font-semibold text-gray-900 dark:text-white">{pageOptions.length}</span>
  </span>
<div className='flex items-center justify-center'>
<button  className=" disabled:opacity-50  inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>previousPage()} disabled={!canPreviousPage} >
  <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
  Atras
</button>
<button  className=" disabled:opacity-50  inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>nextPage()} disabled={!canNextPage}>
  Siguiente
  <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</button>
</div>
</div>
            </div>
  )
}

export default TableRoles