import { useState } from "react";



const useInitialStateDelete = ()=> {


    const [purchaseToDelete,setPurchaseToDelete]=useState({});
    const [showModalDelete,setModalDelete]=useState(false)

   
        return { purchaseToDelete,setPurchaseToDelete,showModalDelete,setModalDelete }

}



export default useInitialStateDelete