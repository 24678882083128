import React, { useRef } from "react";
import { useState } from "react";
import {
  Button,
  Modal,
  Label,
  TextInput,
  Checkbox,
  Spinner,
} from "flowbite-react";
import { createPermission } from "./service";
import AlertSuccess from "../../common/AlertSuccess";
import AlertError from "../../common/AlertError";

function ModalCreatePermission(props) {
  const [show, setShow] = useState(false);
  const form_create_permission = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showAlert,setAlert]=useState(null)
  
  function handleClick() {
    setShow(true);
    form_create_permission.current.reset();
  }

  function handleClose() {
    setShow(false);
    setAlert(null)
  }

  async function handleSubmitPermission(event) {
    event.preventDefault();
    event.stopPropagation()
    setIsDisabled(true);
    setLoading(true);
    setAlert(null)
    const formData = new FormData(form_create_permission.current);

    const newPermission = {
      name: formData.get("name"),
    };

    try {
      const response= await createPermission(newPermission)
      if (response) {
        if (response.status=="success") {
          setLoading(false);
          setAlert(<AlertSuccess message={response.message} setState= {setAlert}></AlertSuccess>)
          setIsDisabled(false);
          form_create_permission.current.reset();
          props.gotoPage(0)
          props.fetchData({ pageIndex:0, pageSize:10 })
          
        } else {
          setLoading(false);
          setIsDisabled(false);
          setAlert(<AlertError message={response.message} setState={setAlert}></AlertError>)
        }
      } 
    } catch (error) {
      setLoading(false);
      setIsDisabled(false);
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }
    
    
    
    /*
      setAlert(<AlertSuccess message={"hello world"} setState= {setAlert}></AlertSuccess>)
    setIsDisabled(false);
    setLoading(false);
    form_create_permission.current.reset();
    
    */
  }



  return (
    <React.Fragment>
      <div>
        <Button onClick={handleClick}>Crear Nuevo</Button>
        <Modal show={show} onClose={handleClose}>
          <form ref={form_create_permission} onSubmit={handleSubmitPermission}>
            <Modal.Header>Crear Nuevo Permiso</Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <div className="w-full flex justify-center items-center">
                  <Button color="gray">
                  <Spinner aria-label="Alternate spinner button example" />
                  <span className="pl-3">Cargando...</span>
                </Button>
                </div>
              ) : (
                <div className="space-y-6">
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      
                        <label
                          for="name"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Nombre
                        </label>
                        <input
                          type="text"
                          id="name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Editar registro"
                          required
                          name="name"
                        />
                      
                    </div>
                  </div>
                </div>
              )}
              {showAlert}
            </Modal.Body>
            <Modal.Footer>
            <Button type="submit" disabled={isDisabled}>
        Guardar
      </Button>
              <Button color="gray" onClick={handleClose} disabled={isDisabled}>
                Cerrar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default ModalCreatePermission;
