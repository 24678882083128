import client, { setAuthorizationHeader,removeAuthorizationHeader} from "../../api/client.js";
import { storage } from "../../utils/storage.js";

export const login= async ({username,password})=>{
    
    try {
        const response = await client.post('/api/login', { email: username, password });
        setAuthorizationHeader(response.access_token);
        storage.set('auth_corpcasas', response.access_token);
    } catch (error) {
        if (error.errors && error.errors.name.length > 0) {
            throw new Error(error.errors.name.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
    
    

}

export const logout= async ()=>{
    
    await Promise.resolve();
    removeAuthorizationHeader();
    storage.remove("auth_corpcasas");
    
}


export const getMe=async ()=>{
    try {
        const response=await client.get("/api/me")
        return response
    } catch (error) {
        if (error.errors && error.errors.length > 0) {
            throw new Error(error.errors.join("-"))
        } else {
            throw new Error(error.message)
        }
    }
}