import React, { useState } from "react";
import FormRegisterPurchase from "../components/FormRegisterPurchase";
import PurchaseContext from "../context/PurchaseContext";
import useInitialStatePurchase from "../hooks/useInitialStatePurchase";

function RegisterPurchase() {
  const initialStatePurchase = useInitialStatePurchase();
  const [showAlert, setAlert] = useState(null);
  return (
    <PurchaseContext.Provider value={initialStatePurchase}>
      <div>
        <div className="flex md:flex-row flex-col gap-4 md:justify-between">
          <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">
            Registro de Compra
          </h2>
          <div>{showAlert}</div>
        </div>

        <FormRegisterPurchase setAlert={setAlert}></FormRegisterPurchase>
      </div>
    </PurchaseContext.Provider>
  );
}

export default RegisterPurchase;
