

import React, { useEffect, useState } from 'react'
import ModalCreateProduct from "../components/products/ModalCreateProduct";
import { Avatar, Dropdown,Button } from "flowbite-react";

import { HiViewGrid, HiCog, HiCurrencyDollar, HiLogout } from "react-icons/hi";
import ModalCreateCategory from '../components/products/Category/ModalCreateCategory';
import { getCategoriesProduct } from '../components/products/Category/service';
import ItemCategoryProduct from '../components/products/Category/ItemCategoryProduct';
import useInitialStateCategoryProduct from '../hooks/products/category/useInitialStateCategoryProduct';
import ModalEditCategoryProduct from '../components/products/Category/ModalEditCategoryProduct';
import ModalEditCategoryProductContext from '../context/Products/category/ModalEditCategoryProductContext';
import useInitialStateDeleteCategoryProduct from '../hooks/products/category/useInitialStateDeleteCategoryProduct';
import ModalDeleteCategoryProductContext from '../context/Products/category/ModalDeleteCategoryProductContext';
import ModalDeleteCategoryProduct from '../components/products/Category/ModalDeleteCategoryProduct';


function CategoriesProduct() {
  const [stateCategories,setStateCategories]=useState([])
  const initiaStateCategory=useInitialStateCategoryProduct()
  const initialStateDeleteCategory=useInitialStateDeleteCategoryProduct()
  const [showAlert,setAlert]=useState(null)
  async function getCategoriesProductList (customParams) {
      try {
        const response=await getCategoriesProduct(customParams)
        setStateCategories(response.data)
      } catch (error) {
        throw new Error(error.message)
      }
  }


  useEffect(()=>{
    getCategoriesProductList({q:"all"})
  },[])


  return (
    <ModalDeleteCategoryProductContext.Provider value={initialStateDeleteCategory}>
        <ModalEditCategoryProductContext.Provider value={initiaStateCategory}>
      <div>
      <h2 className="text-3xl  font-extrabold dark:text-white mb-6">
        <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
          Categorias de producto
        </small>
      </h2>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div className="items-center justify-between lg:flex">
          <div className="mb-4 lg:mb-0">
            <ModalCreateCategory getCategoriesProductList={getCategoriesProductList}></ModalCreateCategory>
            <ModalEditCategoryProduct getCategoriesProductList={getCategoriesProductList}></ModalEditCategoryProduct>
            <ModalDeleteCategoryProduct  showAlert={showAlert} getCategoriesProductList={getCategoriesProductList} setAlert={setAlert}></ModalDeleteCategoryProduct>
          </div>
          <div>
            {showAlert}
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Nombre
                      </th>
                      
                
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                   {stateCategories.map((category)=>(<ItemCategoryProduct key={category.id} objectItem={category} ></ItemCategoryProduct>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between pt-3 sm:pt-6">
          
          <div className="flex-shrink-0">
            
          </div>
        </div>
      </div>
    </div>
    </ModalEditCategoryProductContext.Provider>
    </ModalDeleteCategoryProductContext.Provider>
    
  )
}

export default CategoriesProduct