import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useState } from "react";
import { getExpense } from "./service";
import { Dropdown } from "flowbite-react";
import { HiViewGrid, HiCog, HiCurrencyDollar } from "react-icons/hi";

import {
  getProvidersList,
  getTypeDocumentsList,
  handleBadgeStatePurchase,
} from "../../common/Expense_Purchase_handlers";
import ModalViewExpenseContext from "../../context/Expenses/ModalViewExpenseContext";
import { Link, useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ModalViewExpense from "./ModalViewExpense";
import ModalDeleteExpense from "./ModalDeleteExpense";
import ModalDeleteExpenseContext from "../../context/Expenses/ModalDeleteExpenseContext";
import { getCompanies } from "../companies/service";
import { Pagination } from "@mui/material";

const columnHelper = createColumnHelper();

function TableExpenses({ data, fetchData, pageIndex, pageCount, setPageIndex,filters}) {
  const { state, setState, showModalView, setModalView } = useContext(
    ModalViewExpenseContext
  );

  const {
    expenseToDelete,
    setExpenseToDelete,
    showModalDelete,
    setModalDelete,
  } = useContext(ModalDeleteExpenseContext);

  const columns = [
    columnHelper.accessor("n", {
      header: () => "Nº",
    }),
    columnHelper.accessor(
      (row) => row.type_document_payment?.description || null,
      {
        id: "voucher",
        header: () => "Comprobante",
      }
    ),
    columnHelper.accessor((row) => (row.serie ? row.serie : null), {
      id: "serie",
      header: "Serie",
    }),

    columnHelper.accessor((row) => row.correlative, {
      id: "voucher_number",
      header: "N° de Comprobante",
    }),

    columnHelper.accessor((row) => row.date_expense, {
      id: "date_expense",
      header: "Fecha Emision",
    }),
    columnHelper.accessor((row) => row.supplier_identification_document, {
      id: "number_provider",
      header: "RUC/DNI",
    }),
    columnHelper.accessor((row) => row.sub_total, {
      id: "sub_total",
      header: "Sub Total",
    }),

    columnHelper.accessor((row) => row.total_igv, {
      id: "igv",
      header: "Igv",
    }),

    columnHelper.accessor((row) => row.total, {
      id: "total",
      header: "Total",
    }),

    columnHelper.accessor((row) => row, {
      id: "status",
      header: "Estado",
      cell: (props) => handleBadgeStatePurchase(props.getValue().status),
    }),

    columnHelper.accessor((row) => row, {
      id: "actions",
      header: "Acciones",
      cell: (props) => (
        <Dropdown label="Opciones" size="xs" width="3rem" color="dark">
          <Dropdown.Item
            icon={HiViewGrid}
            onClick={() => handleView(props.getValue())}
          >
            Ver
          </Dropdown.Item>
          <Dropdown.Item icon={HiCog}>
            <Link to={`/edit_expense/${props.getValue().id}`}>Editar</Link>
          </Dropdown.Item>
          <Dropdown.Item
            icon={HiCurrencyDollar}
            onClick={() => onClickShowModalDelete(props.getValue())}
          >
            Eliminar
          </Dropdown.Item>
        </Dropdown>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  function onClickShowModalDelete(object) {
    setExpenseToDelete(object);
    setModalDelete(true);
  }

  async function handleView(state) {
    const expense = await getExpense(state.id);
   
    setModalView(true);
    setState(expense.data);
  }

  const onChangePagination = async (event, page) => {
    
    
    await fetchData({pageIndex:page ,...filters})
    setPageIndex(page);
  };

  return (
    <div>
      <div className="overflow-x-auto rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} scope="col" className="px-6 py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-6 py-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center">
            {data.length === 0 && " No hay datos para mostrar"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
        <Pagination
          page={pageIndex}
          count={pageCount}
          color="primary"
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
}

export default TableExpenses;
