import { useState } from "react";




const initialState = { id:"" , name:""}


function useInitialStateDeleteTypeOperation() {

    const [showModalDelete,setShowModalDelete]=useState(false);
    const [type_operation_to_delete,setTypeOperationToDelete]=useState(initialState)
   


  return { showModalDelete,setShowModalDelete,type_operation_to_delete,setTypeOperationToDelete }

}


export default useInitialStateDeleteTypeOperation