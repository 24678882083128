import React, { useEffect, useState } from "react";
import ModalCreateUser from "../components/users/ModalCreateUser";
import { Avatar, Dropdown, Button } from "flowbite-react";

import { getUsers } from "../components/users/service";
import ItemUser from "../components/users/ItemUser";
import ModalViewUser from "../components/users/ModalViewUser";
import useInitialStateViewUser from "../hooks/Users/useInitialStateViewUser";
import ModalViewUserContext from "../context/Users/ModalViewUserContext";
import ModalEditUserContext from "../context/Users/ModalEditUserContext";
import useInitialStateEditUser from "../hooks/Users/useInitialStateEditUser";
import ModalEditUser from "../components/users/ModalEditUser";

import useInitialStateDeleteUser from "../hooks/Users/useInitialStateDeleteUser";
import ModalDeleteUserContext from "../context/Users/ModalDeleteUserContext";
import ModalDeleteUser from "../components/users/ModalDeleteUser";

import AlertError from "../common/AlertError";

function Users(props) {
  const [userList, setUsersList] = useState([]);
  const initialStateUserView = useInitialStateViewUser();
  const initialStateEditUser = useInitialStateEditUser();
  const initialStateDeleteUser = useInitialStateDeleteUser();
  const [showAlert,setAlert]=useState(null)

  async function getUserList(setState) {
    try {
      const response = await getUsers();
      setUsersList(response.data);
    } catch (error) {
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }
  }


 


  useEffect(() => {
    getUserList();

  }, []);

  return (
    <ModalDeleteUserContext.Provider value={initialStateDeleteUser}>
      <ModalEditUserContext.Provider value={initialStateEditUser}>
        <ModalViewUserContext.Provider value={initialStateUserView}>
          <div>
          <h2 className="text-3xl font-bold  mb-6 text-gray-500 dark:text-gray-400">Usuarios</h2>
            <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
              <div className=" flex md:flex-row flex-col md:justify-between gap-3">
                <div className="">
                  <ModalCreateUser getUserList={getUserList} ></ModalCreateUser>
                  <ModalViewUser></ModalViewUser>
                  <ModalEditUser getUserList={getUserList}></ModalEditUser>
                  <ModalDeleteUser alert={{showAlert,setAlert}} getUserList={getUserList} ></ModalDeleteUser>
                </div>
                <div className="">
                  {showAlert}
                </div>
              </div>

              <div className="flex flex-col mt-6">
                <div className="overflow-x-auto rounded-lg">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                          <tr>
                            <th
                              scope="col"
                              className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                            >
                              Nombre
                            </th>
                            <th
                              scope="col"
                              className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                            >
                              Rol
                            </th>
                            <th
                              scope="col"
                              className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                            >
                              Email
                            </th>

                            <th
                              scope="col"
                              className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                            >
                              Estado
                            </th>

                            <th
                              scope="col"
                              className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                            >
                              Acciones
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800">
                          {userList.map((user) => (
                            <ItemUser
                              user={user.id}
                              objectItem={user}
                            ></ItemUser>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between pt-3 sm:pt-6">
                
                <div className="flex-shrink-0">
                 
                </div>
              </div>
            </div>
          </div>
        </ModalViewUserContext.Provider>
      </ModalEditUserContext.Provider>
    </ModalDeleteUserContext.Provider>
  );
}

export default Users;
