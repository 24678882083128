

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useReactTable,getPaginationRowModel,  } from "@tanstack/react-table";
import GlobalFilter from './GlobalFilter';








function TableExample({columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  metaData
}) {
    
    const tableInstance=useReactTable({columns,data,initialState: { pageIndex: 0 } ,manualPagination: true,pageCount: controlledPageCount},getPaginationRowModel)


    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      state,
      previousPage,
      setPageSize,
      setGlobalFilter,
      // Get the state from the instance
      state: { pageIndex, pageSize },
    } = tableInstance

    const {globalFilter}=state

    useEffect(() => {
      fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

  return (

    <div>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" {...getTableProps()}>
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" >
            
            {headerGroups.map((headerGroup)=>(<tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
                <th scope="col" class="px-6 py-3" {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>))}
            
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td class="px-6 py-4" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        
    </table>
      


<div className='flex items-center justify-center flex-col gap-3 mt-3'>
<span class="text-sm text-gray-700 dark:text-gray-400">
      Pagina <span class="font-semibold text-gray-900 dark:text-white">{pageIndex+1}</span><span class="font-semibold text-gray-900 dark:text-white">{}</span> de <span class="font-semibold text-gray-900 dark:text-white">{pageOptions.length}</span>
  </span>
<div className='flex items-center justify-center'>
<button  className=" disabled:opacity-50  inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>previousPage()} disabled={!canPreviousPage} >
  <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
  Atras
</button>
<button  className=" disabled:opacity-50  inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>nextPage()} disabled={!canNextPage}>
  Siguiente
  <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</button>
</div>
</div>

    </div>
  )
}

export default TableExample