
import { useState } from "react";


const date=new Date()

const initialState = {

    provider:null,
    date_expense:date,
    company:null,
    voucher:{
        type:null
    },
    type_payment:null,
    detailItems:[],
    subTotal:0,
    total:0,
    igv:0,
    status:"",
    responsible:{ id:"",name:""}
}



const useInitialStateExpense = ()=> {

    const [state,setState]=useState(initialState);

    const addToListDetail=(payload)=>{

        setState({
            ...state,
            detailItems:[...state.detailItems,{...payload}]
        })
        
    }


    const removeFromList=(payload)=>{
        setState(
            {
                ...state,
                detailItems:state.detailItems.filter(item=>item.uuid!==payload.uuid)
            }
        )
    }

    const updateItemsDetail=(payload)=>{
       
        const found=state.detailItems.find((item)=>(item.uuid==payload.uuid))
        
        if (found) {
            const updatedItems=state.detailItems.map((item)=>(item.uuid==payload.uuid?payload:item))
            setState({...state,detailItems:updatedItems})
        }else {
            setState({...state,detailItems:[...state.detailItems,payload]})
        }
    }

        return {state,setState,addToListDetail,removeFromList,updateItemsDetail}
}


export default useInitialStateExpense