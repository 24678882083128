import React, { useEffect, useState } from 'react'


import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import AlertError from '../common/AlertError';

import { getPermissions } from '../components/permissions/service';

const animatedComponents = makeAnimated();



function MultiSelect(props) {

    const [options,setOptions]=useState([])

    function handleChange(selectedOption) {

        props.stateValue.setPermissions(selectedOption)
        
      }

   async function handleData() {

    try {
      const response=await getPermissions("?q=all")
        const data=response.data.map((item)=>{
            return { value: item.id , label: item.name }
        })

          setOptions(data)
    } catch (error) {
      props.stateAlert.setAlert(<AlertError message={error.message} setState={props.stateAlert.setAlert}></AlertError>)
    }
      
    }
      
  

      useEffect(()=>{
          handleData()
      },[])

     

    return (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          cacheOptions
          defaultOptions
          isMulti
          options={options}
          onChange={handleChange}
        />
      );
}

export default MultiSelect