import { forwardRef } from "react";


const ButtonLogout= forwardRef((props,ref)=>{

    return <button ref={ref} className="w-full self-start text-left" type={props.type} onClick={props.onClick} disabled={props.disabled} >
    {props.children}
  </button>
})



export default ButtonLogout