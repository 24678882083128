
import { Button, Modal } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import PurchaseContext from "../../context/PurchaseContext";
import { v4 as uuidv4 } from "uuid";
import AlertError from "../../common/AlertError";
import AlertSuccess from "../../common/AlertSuccess";
import { createPurchase, updatePurchase } from "./service";
import { type } from "@testing-library/user-event/dist/type";

function ModalPreviewUpdatePurchase(props) {
  const { removeFromList, state, setState, updateItemsDetail } =
    useContext(PurchaseContext);

    const [isDisabled, setDisabled] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState();
    const [showAlert, setAlert] = useState();

    const types_igv = [
        {value:10,name:"IGV 10 %"},
        {value:18,name:"IGV 18 %"},
        {value:0,name:"Exonerado/Inafecto"}
      ]


  function onClick(params) {
    props.setShowModal(true);
  }

  function onClose(params) {
    props.setShowModal(false);
  }


  async function onSubmitFormPurchase(params) {
    setDisabled(true)
    setIsLoadingSubmit(true)
    const date=new Date(state.date_purchase)
    let products = state.detailItems.map((product)=>{
      return {

        product_id: product.product.id,
        descripcion: product.descriptionItem ,
        quantity: product.quantityProduct,
        unidad: product.unit?product.unit.code:null,
        percentage_igv: product.typeIgv.id?product.typeIgv.id:null,
        igv:Number(product.igv),
        item_amount: product.totalItemPurchase

    }
    })
    

   const provider=props.providersList.find((item)=>(item.value==state.provider.number))
   const type_document= props.typeDocumentsPaymentList.find((document)=>(document.description==state.voucher.type.name))

   

    const updatedPurchase={
      id:state.id,
      date_buy : date.toISOString().split('T')[0],
      type_payment : state.type_payment.id?state.type_payment.id:null,
      type_document_payment_id: type_document?type_document.id:null,
      serie:  state.voucher.serie,
      correlative: state.voucher.correlative,
      supplier_id : provider.id,
      company_id : state.company.id,
      details: [...products],
      total:state.total,
      total_igv:state.igv,
      sub_total:state.subTotal,
      status:state.status
  }

 


    try {
      verifyDataOfForm(updatedPurchase)
      const response = await updatePurchase(updatedPurchase)
      setDisabled(false)
      setIsLoadingSubmit(false)

      setAlert(<AlertSuccess message={response.message} setState={{setAlert}}></AlertSuccess>)
      
    } catch (error) {
      console.log(error)
      setDisabled(false)
      setIsLoadingSubmit(false)
      setAlert(<AlertError message={error.message} setState={setAlert}></AlertError>)
    }
 
  

  }

  function verifyDataOfForm(updatedPurchase) {

    if (updatedPurchase.details.length<=0) {
      throw new Error("Agrega un producto a la lista")
    }

    updatedPurchase.details.forEach(item=> {
        if (!item.product_id) {
            throw new Error("No puede haber un producto vacio en el detalle")
        }
            
        
        if (!item.unidad) {
          throw new Error("Una fila del detalle no tiene una unidad de medida seleccionada")
        }


        if (Number(item.quantity)<=0) {
            throw new Error("Ingrese cantidad del item en el detalle")
        }

        if (!item.percentage_igv) {
          throw new Error("Seleccione unn tipo de igv en el detalle")
        }

      


    });

    //si ha seleccionado boleta o factura debe ingresar el correlativo y serie

    if ( !updatedPurchase.serie || !updatedPurchase.correlative ) {

        throw new Error("Para boleta o factura la serie y correlativo deben estar completados")

    }

    //debe seleccionar la empresa al que corresponse de la compra o gasto

    if (!updatedPurchase.company_id) {
        throw new Error("Debe seleccionar una empresa")
    }

    //debe seleccionar un metodo de pago

    if (!updatedPurchase.type_payment) {
        throw new Error("Debe seleccionar un metodo de pago")
    }

    //debe seleccionar un proveedor

    if (!updatedPurchase.supplier_id) {
      throw new Error("Debe seleccionar un proveedor")
  }
     
     if (!updatedPurchase.status) {
      throw new Error("Seleeciona un estado para compra o gasto")
     }

  }


  useEffect(()=>{
    if (props.showModal) {
      setAlert(null)
    }
  },[props.showModal])


  return (
    <React.Fragment>
      <button
        type="button"
        className="focus:outline-none  justify-self-end  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        onClick={onClick}
      >
        Guardar
      </button>
      <Modal show={props.showModal} onClose={onClose} size="4xl">
        <Modal.Header>Vista Previa para Actualizar</Modal.Header>
        <Modal.Body>
          <form>
            <div class="grid gap-3 mb-4 md:grid-cols-2">
              <div class="grid gap-3 mb-4 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="provider_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Proveedor
                  </label>
                  <input
                    type="text"
                    value={state.provider?state.provider.name:""}
                    id="provider_preview"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="John"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="date_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Fecha de Compra
                  </label>
                  <input
                    type="text"
                    id="date_preview"
                    value={state.date_purchase.toLocaleDateString()}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Doe"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="company_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Empresa
                  </label>
                  <input
                    type="text"
                    id="company_preview"
                    value={state.company.name}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Flowbite"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="type_payment_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tipo de Pago
                  </label>
                  <input
                    type="text"
                    id="type_payment_preview"
                    value={state.type_payment.name}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="123-45-678"
                    pattern="[a-zA-Z0-9-]+"
                    required
                  />
                </div>
              </div>
              <div className="grid gap-x-3 md:grid-cols-3">
                <div>
                  <label
                    htmlFor="voucher_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Comprobante
                  </label>
                  <input
                    type="text"
                    id="voucher_preview"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="flowbite.com"
                    value={state.voucher.type.name}
                    required
                  />
                </div>
                <div>
                  <label
                    for="serie_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Serie
                  </label>
                  <input
                    type="text"
                    id="serie_preview"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    value={state.voucher.serie}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="correlative_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Correlativo
                  </label>
                  <input
                    type="text"
                    id="correlative_preview"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    value={state.voucher.correlative}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="correlative_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Estado
                  </label>
                  {state.status==="PENDIENTE"?(<span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">PENDIENTE</span>):(state.status==="OBSERVADO"?(<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">OBSERVADO</span>):(<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">REVISADO</span>))}
                </div>
                <div>
                  <label
                    htmlFor="correlative_preview"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Responsable
                  </label>
                  <input
                    type="text"
                    id="responsible"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    value={state.responsible?state.responsible.name:null}
                    required
                  />
                </div>
              </div>
            </div>

            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Producto
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Unidad
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Cantidad
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Tipo IGV
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Precio
                    </th>
                  </tr>
                </thead>
                <tbody>
                {state.detailItems.map((item) => ( <tr key={item.uuid}
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {/**
                         verifico si el parametro product es un objeto , en caso lo sea evalua si tiene el campo label y lo coloca en caso no tenga pone null, si no es un objeto coloca el valor que tenga
                         */}
                        {item.product?item.product.name:null}
                      </th>
                      <td class="px-6 py-4">
                        {item.unit?(item.unit.label?item.unit.label:""):null}
                      
                      </td>
                      <td class="px-6 py-4">
                        {item.quantityProduct ? item.quantityProduct : 0}
                      </td>
                      <td class="px-6 py-4">
                        {!item.typeIgv.name?(types_igv.find((type)=>(type.value===item.typeIgv.id))?types_igv.find((type)=>(type.value===item.typeIgv.id)).name:""):item.typeIgv.name}
                      </td>
                      <td class="px-6 py-4">{item.totalItemPurchase}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex mt-4 gap-6">
              <div class="flex">
                <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                  Igv :
                </p>
                <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                  S/ {state.igv}
                </p>
              </div>
              <div class="flex">
                <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                  Total :
                </p>
                <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                 S/ {state.total}
                </p>
              </div>
            </div>
          </form>
          <div className="pt-3">
            {showAlert}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmitFormPurchase} disabled={isDisabled}>Enviar</Button>
          <Button color="gray" onClick={onClose} disabled={isDisabled}>
            Cerrar
          </Button>
          <div className="w-full flex justify-center mb-4">
              {isLoadingSubmit ? (
                <button
                  disabled
                  type="button"
                  class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  Cargando
                </button>
              ) : (
                ""
              )}
            </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ModalPreviewUpdatePurchase;