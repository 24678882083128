import client, {
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "../../api/client.js";

export const getTypeDocumentsPayments = async () => {
  try {
    const response = await client.get(`/api/sunat/type-document-payments`);
    return response;
  } catch (error) {
    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
  }
};

export const createPurchase = async (newPurchase) => {
  try {
    const response = await client.post("/api/buys", newPurchase);
    return response;
  } catch (error) {
    
    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
  }
};


export const getPurchases =async (params)=>{
  try {
    const response=await client.get(`/api/buys`,{params:{...params}})
    
    return response
  } catch (error) {

    
    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
  }
}


export const getPurchase=async (idPurchase)=>{
  
  try {

    const response = await client.get(`/api/buys/${idPurchase}`)
    return response

  } catch (error) {

    console.log(error);

    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
  }

}



export const updatePurchase=async (updatedPurchase)=>{

  try {
    
    const response = await client.put(`/api/buys/${updatedPurchase.id}`,updatedPurchase)
    return response
  } catch (error) {
    
 

    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }

  }

}


export const deletePurchase= async (idPurchase)=>{
  try {

    const response = await client.delete(`/api/buys/${idPurchase}`)
    return response
  } catch (error) {
    
    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
}

}

export const getBuysDetail= async (customParams)=>{

  try {

    const response = await client.get(`api/buys-details`,{params:{...customParams}})
    return response
  } catch (error) {
    
    if (error.errors && error.errors.length > 0) {
      throw new Error(error.errors.join("-"));
    } else {
      throw new Error(error.message);
    }
}
}