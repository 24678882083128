
import React, { useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { getDateFromValue } from '../utils/functions_tools';

function DateRangePicker({nameStart,nameEnd, state,setState}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
      
      const [start, end] = dates;
      
     if (start!==null && end!==null) {
      setState({...state,[nameStart]:getDateFromValue(start),[nameEnd]:getDateFromValue(end)})
     } else if (start===null && end===null) {
      setState({...state,[nameStart]:"",[nameEnd]:""})
     }
      setStartDate(start);
      setEndDate(end);
    };

    

    return (
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        isClearable
        selectsRange
        className='bg-gray-50 border z-[-1] pl-11 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
      />
    );
}

export default DateRangePicker