

import React from 'react'
import { BiTrash } from "react-icons/bi";

function ButtonDeleteItem({uuid,removeItem}) {

  

  return (
    <button onClick={removeItem}><BiTrash className="text-2xl text-pink-700"></BiTrash></button>
  )
}

export default ButtonDeleteItem